import { format, set } from 'date-fns'
import location from '../marker.svg';
import placeholder from '../placeholder.png';
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Link } from "react-router-dom";



function AdCard(props) {

  let [org, setOrg] = useState(null);
  let [orgLocation, setOrgLocation] = useState(null);

  function distance(lat1,
    lon1, loc)
  {
  
  
  const parts = loc.split(','); 
  var lat2 = parts[0]; 
  var lon2 = parts[1]; 
  
  lon1 =  lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;
  console.log(lat1, lon1);
  
  
  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
  + Math.cos(lat1) * Math.cos(lat2)
  * Math.pow(Math.sin(dlon / 2),2);
  
  let c = 2 * Math.asin(Math.sqrt(a));
  
  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 3956;
  
  // calculate the result
  let results = c * r;
  console.log(results);
  return(Math.round( results * 100 + Number.EPSILON ) / 100);
  }


  useEffect(() => {
    setOrgLocation(props.promoOrgs.locations[0])
    props.promoOrgs.providers.map((org) => {
      if(org.cpid == props.promoOrgs.locations[0].cpid){
        setOrg(org)
      }
    });
    
  }, [props.promoOrgs]);

  const linkhandleClick = (idClick) => {
    // e.preventDefault();
    console.log('The link was clicked.', idClick);
    const dataClick = async () => {
        const data = await (
          
          await fetch(
            `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/record-click-article?id=${idClick}`
          )
        ).json();
        console.log('The link was clicked.');
      };
      dataClick();
  }
   
  
  
  
  return (
    <div>
       {org && (              
        <Link   id="resourcead" className="campAdCard" onClick={() => { linkhandleClick(org.cpid) }}  to={org.websiteUrl + "?tutm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"} target="_blank"> 
        { org.ytLink != "" ?
          (<div className="video-responsive" >
          <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${org.ytLink}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          
          />
        </div>
      ):
    <div className="adcardimg"><img alt="Camp Logo" src={"https://static.summercampscout.com/media/providers/" + org.image} /></div>
        }
    <div className="twopromo">
      <h2 className="cardAdTitle">{org.title}</h2>
      <h3 className="campcardLocation"><img className="cardlocation" src={location} />{orgLocation.description}</h3>
      {<p className="addistance">{distance(props.lat, props.lon, orgLocation.geoJson)} miles from {(props.zip == "" || props.zip == null) ? "Zip": props.zip} </p>}
      
      
      
    </div>
      <div className="rightCard threepromo">
      
     
      </div> 
      <div className="promodescription fourpromo" lg="12">
        <p className="addescriptiontext">{org.description}</p>
        <p className="adpromotedtext">Advertisement</p>
      </div>
      </Link>       
       )}
       </div>
  )
}

export default AdCard;
