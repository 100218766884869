
import { useState, useEffect, useInsertionEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Footer from '../components/footer';
import ReactMarkdown from 'react-markdown';
import PageHeader from '../components/pageHeader';

import { Container, Row, Col, Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import DemoCard from '../components/DemoCard';
import DemoPromoCard from "../components/DemoPromoCard";
import AdDemoCard from "../components/AdDemoCard";


const query = `query{
    pagesCollection(where: { slug: "submit-camps" }, limit: 1){
      items{
        title
        metaDescription
        content
        }
        }
    }` 


export default function SubmitCamps(props)  {
    const [page, setPage] = useState(null);
  

  
  


    useEffect(() => {
        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
    
            // rerender the entire component with new data
            console.log(data);
            setPage(data.pagesCollection.items[0]);
            document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
      
          });
      }, []);
    
     

useEffect(() => {
  document.title = "Submit Camps | Summer Camp Scout";  
}, []);

  if(!page) {
    return "loading!..."
  }else{

    return (
      <div>
        <PageHeader title={"Submit Camps"}/>
      
        <div className="contactWrapper">
        
        <Container className="contact-content">
        <Row>
           <Col className="adTitle" md={{span: 12}} lg={{span:10, offset:1}}>
         
          <div className="pageContentAdvertising">
          <ReactMarkdown>{page.content}</ReactMarkdown>
          </div>

          </Col>
          </Row>
          <Row className="campSubWrapper">
          <Col className="campSubBox" md={{span: 6 }}>
          <div className="campsubcontainer">
          <h2>Submit individual camps</h2>
          <p>Minimum information for each camp includes:</p>
          <ol>
          <li>Camp & Organization Name</li>
          <li>Min & Max Age</li>
          <li>Dates & Times</li>
          <li>Cost</li>
          <li>Address</li>
          
          </ol>
          
          <div className="subBtnContainer">
          <p><span className="spanCost">Cost:</span> <span className="spanFree">FREE</span> (optional promotion available)</p>
            <div className="subLinkBtn">
              { /* <button  className="subbuttonOrange" style={{border:"none", backgroundColor:"grey"}}>Coming Feb 1</button> */ }
              <a href="https://provider.summercampscout.com" className="subbuttonOrange">Get Started</a>
            </div>
            </div>
          </div>
          </Col>
          <Col className="orgSubBox" md={{span: 6 }}>
          <div className="campsubcontainer">

          <h2>Promote my organization</h2>
          <p>Requires information specific to your organization including:</p>
<ol>
<li>Organization Name</li>
<li>Min & Max Age (across all camps)</li>
<li>Start & End Dates (across all camps)</li>
<li>Address</li>
<li>Description</li>
<li>Image or Video (optional)</li>
</ol>
<div className="subBtnContainer">
<p><span className="spanCost">Cost:</span> <span className="spanFree">$299</span> (additional promotions available)</p>
<div className="subLinkBtn">
          <a href="https://provider.summercampscout.com" className="subbuttonOrange">Get Started</a>
          </div>
          </div>
          </div>
          </Col>
          </Row>
          </Container>
          <Container className="ad-content">
    <Row className="campExamples">
    <Col xl={{span: 6, offset: 1 }} md={{span:7}} sm={{span:12}}>
    <h3 className="adCardMainHeader">Camp Listing Examples</h3>
        <DemoCard  promoType={"Standard Listing"} />
        
        <AdDemoCard promoType={"Premium Listing"}/>
    </Col>
        
    
    <Col xl={{span: 4}} md={{span:5}} sm={{span:12}}> 
    <h3 className="adCardMainHeader">Organization Promo Example</h3>
    <Col className="providerCard" lg="12">
    <DemoPromoCard />
    </Col>
    </Col>
    </Row>
    </Container>
          </div>
</div>
    );
  
};
}
