import { Outlet, Link, useNavigate, createSearchParams } from "react-router-dom";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";


class Footer extends React.Component {
    render(props) {
      return (
        <footer className="page-footer">
            <Row className="footerWrapper">
            <Col md="6" className="footer-left">
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/cookie-settings">Cookie Settings</Link>
                <Link to="/sitemap">Sitemap</Link>
                {/* <Link to="/sitemap">Sitemap</Link> */}
                
                </Col>
                <Col md="6" className="footer-right">
                <Link to="/resources">Resources</Link>
                <Link to="/faq">FAQs</Link>
                <Link to="/contact">Contact</Link>
                
                </Col>
                </Row>
            </footer>
      );
    }
  }

  export default Footer;