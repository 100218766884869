import { useNavigate, createSearchParams, useLocation, useParams, Link } from "react-router-dom";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import sliderIcon from '../slider-icon.png';
import Card from '../components/card';
import Advertisement from '../components/Advertisement';
import PromoCard from '../components/promocard';
import SimpleMap from "../components/map";
import closeBtn from '../close.svg';
import arrow from '../arrow.png';
import moment from 'moment'
import { format, set } from 'date-fns'
import Lottie from "react-lottie";
import * as animationData from "../location-pin.json";
import ReactMarkdown from 'react-markdown';
import ProviderCard from '../components/ProviderCard';




// const render = (status: Status): ReactElement => {
//   if (status === Status.LOADING) return <h3>{status} ..</h3>;
//   if (status === Status.FAILURE) return <h3>{status} ...</h3>;
//   return null;
// };

function extractFromAdress(components, type) {
  for (var i = 0; i < components.length; i++)
    for (var j = 0; j < components[i].types.length; j++)
      if (components[i].types[j] == type) return components[i].short_name;
  return "";
}





export default function Home(props) {


  let [mapWidth, setMapWidth] = useState(false);
  let [campsDB, setCampsDB] = useState(null);
  let [campsFound, setCampsFound] = useState(0)
  let [promoOrgs, setPromoOrgs] = useState(null);
  const zipInput = useRef(null);
  const searchInput = useRef(null);



  let [citySearch, setCitySearch] = useState("");
  let [zipcodeSearch, setZipcodeSearch] = useState("");
  let [stateSearch, setStateSearch] = useState("");

  const navigate = useNavigate();
  let location = useLocation();
  const { userName } = useParams();

  const mapLarge = event => {
    if (mapWidth == false) {
      setMapWidth(true);
    } else {
      setMapWidth(false);
    }
  };
  let currentData = moment().toISOString()



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleChangeDay = () => props.searchCheckedDay();
  const handleChangeNight = () => props.searchCheckedNight();
  const handleChangeAllDay = () => props.searchCheckedAllday();
  const handleSpecialNeeds = () => props.changeSpecialNeeds();
  const handleChangeAm = () => props.searchCheckedAm();
  const handleChangePm = () => props.searchCheckedPm();

  const incrementStart = (c) => props.setStart((c));
  const incrementEnd = (c) => props.setEnd((c));
  const incrementCost = (c) => props.costSet((c));
  const incrementAge = (c) => {
    if (c == '') {
      props.ageSet((0))
    } else {
      props.ageSet((c))
    }

  };

  useEffect(() => {
    // write data to csv
    const dataFetchOne = async () => {
      const data = await (

        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-promotions?distance=${100}&lat=${props.lat}&lon=${props.lon}`
        )
      ).json();
      setPromoOrgs(data)
    };
    if (props.hasLoaded && props.selectDistance && props.lat && props.lon) {
      dataFetchOne();
    }

  }, [props.lon, props.lat, props.selectDistance, props.hasLoaded]);

  useEffect(() => {

    const dataFetchOne = async () => {
      setCampsFound("");
      // setCampsDB(null);
      const data = await (
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/camps?distance=${props.selectDistance}&lat=${props.lat}&lon=${props.lon}`
        )
      ).json();

      //console.log(data)
      setCampsDB(data);
      // setCampsFound(data.length);
      if (data.length > 0) {
        setCampsFound(data.length);
      } else {
        setCampsFound(0);
      }

    };
    if (props.hasLoaded && props.selectDistance && props.lat && props.lon) {
      dataFetchOne();
    }
  }, [props.lon, props.lat, props.selectDistance, props.hasLoaded]);



  useEffect(() => {
    if (campsDB != null) {
      //setCampsFound(0)
      if ((props.searchTerm == "" || props.searchTerm == "") && (props.startDate == 0 && props.endDate == 0)) {
        campsDB.map((camp) => {
          // let day
          let testtimeOne = camp.startDate
          let testtimeTwo = camp.endTime
          let newDate = (Number(testtimeTwo) - Number(testtimeOne))
          if (
            ((moment(new Date()).format('MM/DD/YYYY') <= moment(camp.startDate).format('MM/DD/YYYY')))
            && ((camp.minAge <= props.age && camp.maxAge >= props.age) || props.age == 0)
            && (camp.cost <= props.cost || props.cost == 100)
            && ((camp.type == 'Overnight' && props.checkedNight == true) || (camp.type == 'Day' && props.checkedDay == true) || (props.checkedDay == false && props.checkedNight == false))
            && (((testtimeOne <= 10) && (newDate < 5) && (props.checkedAm == true)) ||
              ((newDate > 5) && (props.checkedAllDay == true)) ||
              ((props.checkedPm == true) && (testtimeOne > 10)) ||
              ((props.checkedAm == false) && (props.checkedPm == false) &&
                (props.checkedAllDay == false))) &&
            ((props.specialNeeds == true && camp.specialNeeds == true) || (props.specialNeeds == false && camp.specialNeeds == false) || (props.specialNeeds == false && camp.specialNeeds == true))
          ) {
            // console.log(camp.rangeKey)
            var add = document.getElementById(camp.rangeKey);
            if (add != null) {
              add.style.display = 'grid';
              add.classList.add("active");
            }

          } else {
            // var remove = document.getElementById(camp.rangeKey);
            // console.log(remove);
            // console.log(camp.rangeKey)
            // if(remove != null){
            // remove.style.display = 'none';
            // remove.classList.remove("active");
            // }
          }
        })
      } else if ((props.searchTerm != "" || props.searchTerm != "") && (props.startDate == 0 && props.endDate == 0)) {
        campsDB.map((camp) => {
          // let day
          let testtimeOne = camp.startDate
          let testtimeTwo = camp.endTime
          let newDate = (Number(testtimeTwo) - Number(testtimeOne))
          if (
            (camp.title.toUpperCase().includes(props.searchTerm) || camp.organization.toUpperCase().includes(props.searchTerm)) &&
            ((moment(new Date()).format('MM/DD/YYYY') <= moment(camp.startDate).format('MM/DD/YYYY')))
            && ((camp.minAge <= props.age && camp.maxAge >= props.age) || props.age == 0)
            && (camp.cost <= props.cost || props.cost == 100)
            && ((camp.type == 'Overnight' && props.checkedNight == true) || (camp.type == 'Day' && props.checkedDay == true) || (props.checkedDay == false && props.checkedNight == false))
            && (((testtimeOne <= 10) && (newDate < 5) && (props.checkedAm == true)) ||
              ((newDate > 5) && (props.checkedAllDay == true)) ||
              ((props.checkedPm == true) && (testtimeOne > 10)) ||
              ((props.checkedAm == false) && (props.checkedPm == false) &&
                (props.checkedAllDay == false))) &&
            ((props.specialNeeds == true && camp.specialNeeds == true) || (props.specialNeeds == false && camp.specialNeeds == false) || (props.specialNeeds == false && camp.specialNeeds == true))
          ) {
            //console.log(camp.rangeKey)
            var add = document.getElementById(camp.rangeKey);
            if (add != null) {
              add.style.display = 'grid';
              add.classList.add("active");
            }

          } else {
            var remove = document.getElementById(camp.rangeKey);
            remove.style.display = 'none';
            remove.classList.remove("active");
          }
        })

      } else {
        campsDB.map((camp) => {
          let testtimeOne = camp.startDate
          let testtimeTwo = camp.endTime
          let newDate = (Number(testtimeTwo) - Number(testtimeOne))
          if (
            (camp.title.toUpperCase().includes(props.searchTerm) || camp.organization.toUpperCase().includes(props.searchTerm))
            && ((moment(props.startDate).format('MM/DD/YYYY') <= moment(camp.startDate).format('MM/DD/YYYY')) && (moment(props.endDate).format('MM/DD/YYYY') >= moment(camp.endDate).format('MM/DD/YYYY')))
            && ((camp.minAge <= props.age && camp.maxAge >= props.age) || props.age == 0)
            && (camp.cost <= props.cost || props.cost == 100)
            && ((camp.type == 'Overnight' && props.checkedNight == true) || (camp.type == 'Day' && props.checkedDay == true) || (props.checkedDay == false && props.checkedNight == false))
            && (((testtimeOne <= 10) && (newDate <= 5) && (props.checkedAm == true)) || ((newDate > 5) && (props.checkedAllDay == true)) || ((props.checkedPm == true) && (testtimeOne > 10)) || ((props.checkedAm == false) && (props.checkedPm == false) && (props.checkedAllDay == false))) &&
            ((props.specialNeeds == true && camp.specialNeeds == true) || (props.specialNeeds == false && camp.specialNeeds == false) || (props.specialNeeds == false && camp.specialNeeds == true))
          ) {

            var add = document.getElementById(camp.rangeKey);
            add.style.display = 'grid';
            add.classList.add("active");

          } else {
            var remove = document.getElementById(camp.rangeKey);
            remove.style.display = 'none';
            remove.classList.remove("active");
            console.log(camp.rangeKey)
          }
        })
      }
      const collection = document.getElementsByClassName("active");
      //setCampsFound(collection.length);
    }

    const collection = document.getElementsByClassName("active");
    setCampsFound(collection.length);


    const params = new URLSearchParams(location.search);
    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_content = params.get('utm_content');
    const fbclid = params.get('fbclid');



    const options = {

      search: `l=${props.zipcode}&s=${props.searchTerm == '' ? "All" : props.searchTerm}${fbclid != null ? '&fbclid=' + fbclid : ''}${utm_content != null ? '&utm_content=' + utm_content : ''}${utm_source != null ? '&utm_source=' + utm_source : ''}${utm_source != null ? '&utm_medium=' + utm_medium : ''}${utm_campaign != null ? '&utm_campaign=' + utm_campaign : ''}`,
    };



    if (location.pathname == "/") {
      navigate(options, { replace: true });
    } else {
      // var newLocations = location.pathname.split( '/' );
      // if(newLocations.length > 1){
      //   setZipTest(newLocations[2])
      //   console.log(newLocations)
      // }else{
      //   setZipTest(newLocations[1])
      //   console.log(newLocations);
      // }
    }

  }, [campsDB, props.searchTerm, props.age, props.cost, props.startDate, props.endDate, props.zipcode, campsFound, props.checkedNight, props.checkedDay, props.checkedAllDay, props.checkedPm, props.checkedAm, props.specialNeeds]);


  function showAllCamps(e) {
    e.preventDefault();
    //console.log('You clicked submit.');
    props.setSelectDistance(parseInt(2000));
  }

  function setFilterSettings(filterSetting) {
    props.setFilter(filterSetting)
    props.setFilterDescription(true)
  }
  function filter() {
    props.clearFilter()
    var removeStart = document.getElementById("startdate");
    var removeEnd = document.getElementById("enddate");
    var removeAge = document.getElementById("age");
    removeStart.value = ""
    removeEnd.value = ""
    removeAge.value = ""
  }

  const onChange = (event) => {
    const value = event.target.value;

    if (value == "DEFAULT") {
      props.setSelectDistance(25);
    } else {
      props.setSelectDistance(parseInt(value));
    }
    //props.setSelectDistance(parseInt(value));
  };

  if (!campsDB || !promoOrgs) return <div className="loading"><Container>
    <Lottie options={defaultOptions}
      height={100}
      width={100}
    />
    <h1>Retrieving location data...</h1>
  </Container></div>;
  return (
    <div>

      <Container fluid className={`${props.alert ? 'main-content' : 'main-content noalert'}`}>
        <Row>
          <Col lg="2" md="4" className="filterwrapper">

            <div className="mainfilter">
              <div className="filter-wrapper-top">
                <h3 className="filterTitleDistance">Search Radius</h3>
                <select defaultValue={'DEFAULT'} onChange={onChange} id="distanceSelect" className="distance">
                  <option value="3">3 miles</option>
                  <option value="5">5 miles</option>
                  <option value="10">10 miles</option>
                  <option value="DEFAULT">25 miles</option>
                  <option value="100">100 miles</option>
                  <option value="1000">1000 miles</option>

                </select>
              </div>
              <div>
              </div>

              <div className="filter-wrapper filterHide" className={props.filter ? 'filter-wrapper filterShow' : 'filter-wrapper filterHide'}>
                <div className="filterTitleMobile" >
                  <h3 className="filterTitle">Filter</h3>
                  {(props.startDate != 0 || props.endDate != 0 || props.age != 0) &&
                    <button className="clearAll" onClick={() => filter()}>Clear All</button>
                  }
                  <button type="button" className="filterCloseBtn" onClick={() => setFilterSettings(!props.filter)}><img className="closeFilterBtn" src={closeBtn} /></button>
                </div>
                <div className="filterSectionAge">
                  <div className="filterSection">
                    <label className="ageLabel">Age of Child <span>(in years)</span></label>
                    <input className="filter age" type="number" onChange={event => incrementAge(event.target.value)} id="age" />
                  </div>
                  <div className="filterSection specialneeds">
                    <label className="checkLabel">

                      <input
                        type="checkbox"
                        checked={props.specialNeeds}
                        onChange={handleSpecialNeeds}
                      />
                      Special Needs Friendly
                    </label>
                  </div>
                </div>
                <div className="filterSectionType">
                  <label className="typeLabel">Camp Type</label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedDay}
                      onChange={handleChangeDay}
                    />
                    Day
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedNight}
                      onChange={handleChangeNight}
                    />
                    Overnight
                  </label>

                </div>
                <div className="filterSection">
                  <div className="dateSection">
                    <label className="datelabel">Start Date</label>
                    <input className="filter startdate form-control" placeholder="Start Date" type="date" onChange={event => incrementStart(event.target.value)} onFocus={(e) => e.target.type = 'date'} id="startdate" />
                  </div>
                  <div className="dateSection">
                    <label className="datelabel">End Date </label>
                    <input className="filter enddate form-control" placeholder="End Date" type="date" onChange={event => incrementEnd(event.target.value)} onFocus={(e) => e.target.type = 'date'} id="enddate" />
                  </div>
                </div>


                <div className="filterSectionType">
                  <label className="typeLabel">Times</label>
                  <label className="checkLabel">

                    <input
                      type="checkbox"
                      checked={props.checkedAllDay}
                      onChange={handleChangeAllDay}
                    />
                    All Day
                  </label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedAm}
                      onChange={handleChangeAm}
                    />
                    AM
                  </label>

                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedPm}
                      onChange={handleChangePm}
                    />
                    PM
                  </label>
                </div>

              </div>
            </div>


          </Col>
          <Col lg={mapWidth == true ? 4 : 6} md="8">
            <Row>
              <div className="campFoundWrapper">
                {!props.filterDescription ? <img className="arrow" src={arrow} /> : <></>}
                {props.filterDescription}
                <p className="campFoundMobile">Camps Found: <span>{campsFound}</span></p>
                {!props.filterDescription ? <p className="filterDirections">Use filters and search to narrow results.</p> : <></>}
              </div>
              <div className="breadcrumbSection">
                <p>{props.citySearch}&nbsp; &nbsp;>&nbsp;&nbsp; {props.searchTerm}</p>
                <h1 className="headline">{props.searchTerm ? props.searchTerm : "SUMMER "} CAMPS in {props.citySearch}</h1>
              </div>

              <p className="campFound">Camps Found: <span>{campsFound}</span></p>
              {//promoOrgs.providerIds[0] && 
                //<Col className="providerCard" lg="12" key={promoOrgs.providerIds[0]}>
                //<ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={0} zip={props.zipcode} specialNeeds={false}/>
                //</Col>
              }
              {promoOrgs.providerIds[0] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[0]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={0} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[1] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[1]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={1} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[2] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[2]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={2} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[3] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[3]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={3} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[4] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[4]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={4} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[5] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[5]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={5} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[6] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[6]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={6} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[7] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[7]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={7} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[8] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[8]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={8} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[9] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[9]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={9} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {promoOrgs.providerIds[10] &&
                <Col className="providerCard" lg="12" key={promoOrgs.providerIds[10]}>
                  <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={10} zip={props.zipcode} specialNeeds={false} />
                </Col>
              }
              {campsDB.map((camp, index) => {
                const campLstLon = camp.geoJson.split(",");
                return (


                  <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" key={camp.rangeKey}>
                    {camp.promoted ?
                      <PromoCard
                        zip={props.zipcode}
                        loclat={props.lat}
                        loclon={props.lon}
                        registrationOpens={camp.registrationDate}
                        campid={camp.rangeKey}
                        title={camp.title}
                        organization={camp.organization}
                        maxAge={camp.maxAge}
                        dayOfTheWeek={camp.dayOfTheWeek}
                        minAge={camp.minAge}
                        locationDescription={camp.locDescription}
                        lat={campLstLon[0]}
                        lon={campLstLon[1]}
                        startDate={camp.startDate}
                        endDate={camp.endDate}
                        endDateTime={camp.endTime}
                        startDateTime={camp.startTime}
                        type={camp.type}
                        cost={camp.cost}
                        campWebsite={camp.websiteUrl}
                        campImage={camp.image}
                        specialNeeds={camp.specialNeeds}
                        campDescription={camp.description}
                      /> : <></>}
                  </Col>

                )
              })}

              {campsDB.map((camp, index) => {
                const campLstLon = camp.geoJson.split(",");

                if (index == 0) {
                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>

                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>

                    </div>
                  )
                } else if (index == 1) {

                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>

                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>
                      {//promoOrgs.providerIds[1] && 
                        //<Col className="providerCard" lg="12" key={promoOrgs.providerIds[1]}>
                        //<ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={1} zip={props.zipcode} specialNeeds={false}/>
                        //</Col>
                      }
                    </div>
                  )
                }
                else if (index == 6) {

                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>
                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>
                      {//promoOrgs.providerIds[2] && 
                        //<Col className="providerCard" lg="12" key={promoOrgs.providerIds[2]}>
                        //<ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={2} zip={props.zipcode} specialNeeds={false}/>
                        //</Col>
                      }
                    </div>
                  )
                }
                else if (index == 10) {

                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>
                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>
                      {//promoOrgs.providerIds[3] && 
                        // <Col className="providerCard" lg="12" key={promoOrgs.providerIds[3]}>
                        // <ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={3} zip={props.zipcode} specialNeeds={false}/>
                        // </Col>
                      }
                    </div>
                  )
                }
                else if (index == 17) {

                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>
                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>
                      {//promoOrgs.providerIds[4] && 
                        //<Col className="providerCard" lg="12" key={promoOrgs.providerIds[4]}>
                        //<ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={4} zip={props.zipcode} specialNeeds={false}/>
                        // </Col>
                      }
                    </div>
                  )
                }
                else if (index == 23) {

                  return (
                    <div className="campStandardCard" id={camp.rangeKey} key={camp.rangeKey}>
                      <Col className={`campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" >
                        {!camp.promoted ?
                          <Card name="card"
                            zip={props.zipcode}
                            loclat={props.lat}
                            loclon={props.lon}
                            registrationOpens={camp.registrationDate}
                            campid={camp.rangeKey}
                            title={camp.title}
                            organization={camp.organization}
                            maxAge={camp.maxAge}
                            dayOfTheWeek={camp.dayOfTheWeek}
                            minAge={camp.minAge}
                            locationDescription={camp.locDescription}
                            lat={campLstLon[0]}
                            lon={campLstLon[1]}
                            startDate={camp.startDate}
                            endDate={camp.endDate}
                            endDateTime={camp.endTime}
                            startDateTime={camp.startTime}
                            type={camp.type}
                            cost={camp.cost}
                            campWebsite={camp.websiteUrl}
                            specialNeeds={camp.specialNeeds} /> : <></>}
                      </Col>
                      {//promoOrgs.providerIds[5] && 
                        //<Col className="providerCard" lg="12" key={promoOrgs.providerIds[5]}>
                        //<ProviderCard lat={props.lat} lon={props.lon} promoOrgs={promoOrgs} promoNumber={5} zip={props.zipcode} specialNeeds={false}/>
                        //</Col>
                      }
                    </div>
                  )
                }
                else {
                  return (


                    <Col id={camp.rangeKey} className={`campStandardCard campCardSection ${camp.promoted ? 'promo' : ''} ${!camp.active ? 'camp-noshow' : ''}`} lg="12" key={camp.rangeKey}>
                      {!camp.promoted ?
                        <Card name="card"
                          zip={props.zipcode}
                          loclat={props.lat}
                          loclon={props.lon}
                          registrationOpens={camp.registrationDate}
                          campid={camp.rangeKey}
                          title={camp.title}
                          organization={camp.organization}
                          maxAge={camp.maxAge}
                          dayOfTheWeek={camp.dayOfTheWeek}
                          minAge={camp.minAge}
                          locationDescription={camp.locDescription}
                          lat={campLstLon[0]}
                          lon={campLstLon[1]}
                          startDate={camp.startDate}
                          endDate={camp.endDate}
                          endDateTime={camp.endTime}
                          startDateTime={camp.startTime}
                          type={camp.type}
                          cost={camp.cost}
                          campWebsite={camp.websiteUrl}
                          specialNeeds={camp.specialNeeds} /> : <></>}
                    </Col>


                  )
                }
              })}


              {campsFound == 0 ?
                <Col lg="12">


                  <div className="no-results">
                    <h2>No Summer Camps found near <span>{props.zipcode}</span></h2>
                    <ReactMarkdown>{props.globalData.noCampsFound}</ReactMarkdown>

                    <a href="https://provider.summercampscout.com/" className="orangeBtn">Submit Camps</a>

                    <div className="noCampEmail">
                      <p><span className="linkBlue">To get notified</span> when summer camps near you become available, please enter your email below.</p>
                      <div className="noCampEmailForm">

                        <form id="campNotificationFormHome">
                          <div className="Icon-inside" id="home-form">
                            <input className="input-field" type="email" name="email" placeholder="Email address"
                            />
                            <input className="input-field formZip" type="number" name="zip" placeholder="Zip"
                            />
                            <button>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </Col>
                : <></>}

            </Row>
          </Col>
          <Col className="mapsection" lg={mapWidth == true ? 6 : 4} >
            <button className="expand" onClick={mapLarge}>
              Expand Map
            </button>


            <SimpleMap camps={campsDB} lat={props.lat} lon={props.lon} searchTerm={props.searchTerm} startDate={props.startDate} endDate={props.endDate} age={props.age} cost={props.cost} />

          </Col>
        </Row>
      </Container>

    </div>
  );
}
