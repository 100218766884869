import { format, set } from 'date-fns'
import location from '../marker.svg';
import promoImg from '../addemocard.png';

function AdDemoCard(props) {
  



  return (
    <div>
    <button className="promoBtnType">{props.promoType}</button>
    <a htref="#" className="campcardPromo adDemoCard" target="_blank">
    <div className="proplogo onepromo"><img src={promoImg} /></div>
    <div className="twopromo">
      <h2 className="blue cardTitle">Superheroes <span>| Kidz Academy</span></h2>
      <h3 className="campcardLocation"><img className="cardlocation" src={location} />Pacific Science Center</h3>
      {<p className="carddistance">3.14 miles from 98115</p>}
      <p className="camp-date"><span>Aug 07 - Aug 11</span></p>
     
      
     
      <p className="camp-date">Mon - Fri, 9:00AM - 4:00PM</p>
      <p className="registration">Registration: <span className="green">Open</span></p>
      
      
    </div>
      <div className="rightCard threepromo">
      <p className="camp-age">Ages: 6 - 8</p>
      <p className="campcost">$579</p>
      </div> 
      <div className="promodescription fourpromo" lg="12">
        <p className="promodescriptiontext">Use your super science skills to design a super vehicle, test your super senses, and mix up super solutions in the lab. Combine your favorite powers to create your superhero identity, complete with a costume and gadgets.
        </p>
        <p className="promotedtext">Promoted</p>
      </div>
      </a>       
      </div>          
  );
}

export default AdDemoCard;
