import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect, useInsertionEffect, useRef } from "react";
import PageHeader from '../components/pageHeader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import facebook from '../fb.png';
import twitter from '../tw.png';
import back from '../back.svg';
import {
  EmailShareButton,
  FacebookShareButton, FacebookIcon,
  TwitterShareButton,
} from "react-share";
import { Outlet, Link, useNavigate, createSearchParams,useLocation,useParams } from "react-router-dom";

import AdCard from '../components/AdCard';
import link from '../link.svg';
import NEWSCARD from "../components/newscard";

import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import FAQ from "../components/faq";


const query = `query GetArticleByUrl($url: String!) {
  articleCollection(
    limit: 10
    where: {url: $url}
  ) {
    items {
      sys {
        id
      }
      title
      url
      description
      publishDate
      imageFocusArea
      featuredImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      relatedArticles: relatedArticlesCollection(limit:3){
        items{
          title
          url
          imageFocusArea
          description
          featuredImage {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
      content{
        json
        links {
          entries {
            block {
              __typename
              ... on Article {
                sys {
                  id
                }
                title
                url
                description
                imageFocusArea
                featuredImage {
                  title
                  description
                  url
                }
              }
            }
          }
        }
    
      }
    }
  }
}` 

const NewsArticle = (props) => {
      const navigate = useNavigate();

    const [page, setPage] = useState(null);
  const [index, setIndex] = useState(0);
  let [lon, setLon] = useState(null);
  let [lat, setLat] = useState(null);
  let [zipcodeSearch, setZipcodeSearch] = useState("");
  let [zip, setZip] = useState(null);
  let [search, setSearch] = useState("all");
  let [promoOrgs, setPromoOrgs] = useState(null);
  const searchInput = useRef(null);


  function extractFromAdress(components, type){
    for (var i=0; i<components.length; i++)
        for (var j=0; j<components[i].types.length; j++)
            if (components[i].types[j]==type) return components[i].short_name;
    return "";
  }


  

  var newLocations = window.location.pathname.toString().split('/'); 
  const variables = { url: newLocations[2]};
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query, variables }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data.articleCollection.items[0].imageFocusArea[0]);
        setPage(data.articleCollection.items);
        document.querySelector('meta[property="og:title"]').setAttribute("content", data.articleCollection.items[0].title);
        document.querySelector('meta[property="og:description"]').setAttribute("content", data.articleCollection.items[0].description);
        document.querySelector('meta[property="og:image"]').setAttribute("content", data.articleCollection.items[0].featuredImage.url);
        document.querySelector('meta[property="og:url"]').setAttribute("content",  "https//:summercampscout.com/resources/" + data.articleCollection.items[0].url);
        document.querySelector('meta[name="twitter:image:src"]').setAttribute("content", data.articleCollection.items[0].featuredImage.url);
        document.querySelector('meta[name="twitter:site"]').setAttribute("content",  "https//:summercampscout.com/resources/" + data.articleCollection.items[0].url);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", data.articleCollection.items[0].title);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", data.articleCollection.items[0].description);
        document.querySelector('meta[name="description"]').setAttribute("content", data.articleCollection.items[0].description);
      });
  }, []);


  

  useEffect(() => {
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity
    };
        
  //   navigator.geolocation.getCurrentPosition(function(position) {
  //     //console.log(position);
  //     setLat(position.coords.latitude);
  //     setLon(position.coords.longitude);
  //   //console.log("geolocation set : " + position)
  //     // setHasLoaded(true);
            
    
  // },function(error) {
    fetch('https://ipinfo.io?token=be0eeb1f8c4e01')
  .then(response => response.json())
  .then(data => {
    var location = data.loc.split(",");
    console.log(data);
      setLat(location[0]);
      setLon(location[1]);
      setZip(data.postal);
        
      // setHasLoaded(true);
  })
  .catch(error => console.error('Error:', error));
      
  // }, options);


  }, []);

  useEffect(() => {
    // write data to csv
    const dataFetchOne = async () => {
      const data = await (
        
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-promotions-articles?distance=${100}&lat=${lat}&lon=${lon}`
        )
      ).json();
      setPromoOrgs(data)
      // console.log(JSON.stringify(data))
    };
    if( lat && lon) {
      dataFetchOne();
    }
    
  }, [lon,lat]);


  useEffect(() => {
    const keyDownHandler = event => {
      //console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();
        setSearch(searchInput.current.value);
        navigate(`/?l=${zip}&s=${searchInput.current.value}`);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const CustomComponent = ({ node }) => {
    // console.log( "node:", node)
    return(
    <a href={node.url} className="inlineArticle">
      <div className="inlineArticleImg"><img src={node.featuredImage.url + "?fm=jpg&w=486&h=324&fit=fill&f=" + node.imageFocusArea[0] + "&q=90"} /></div>
      <div className="inlineArticleContent"><h3>{node.title}</h3><p>{node.description}</p><img src={link} alt="view article" alt={node.description}/></div>
     
      
    </a>
  )};
  

  const website_url = 'https://summercampscout.com';

  const renderOptions = {
    renderNode: {

      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        var data;
        page[0].content.links.entries.block.forEach(function (entry) {
          console.log(entry)
          if(entry.sys.id == node.data.target.sys.id){
            
            data = entry;
          }
        })
        // [0].sys.id
        // [0]title
        // [0]featuredImage
        // [0]title
        // [0]url

        console.log("data: " + JSON.stringify(data))
        return <CustomComponent node={data}/>
      },
      
      [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => (
        <a className="resourceLink"
          href={JSON.stringify(data)}
          
        >{children}</a>
      ),
      [INLINES.HYPERLINK]: ({ data }, children) => (
        <a className="resourceLink"
          href={data.uri}
          rel={`${data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}
        >{children}</a>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target.sys.contentType.sys.id === "article") {
          return (
            <a className="resourceLink" href={`/blog/${node.data.target.fields.slug}`}>
              {node.data.target.fields.title}
            </a>
          );
        }
      },
  
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            height={node.data.target.fields.file.details.image.height}
            width={node.data.target.fields.file.details.image.width}
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };
  

      
        if (page) {
        return (
        <div>
       
        <PageHeader title={""}/>
        <Container fluid className="articleWrapper">
        <Container className="articleSec backToResources">
        <Row>
         <Col xl={{span: 12 }}>
         <a className="newsBack" href="/resources"><img className="back" src={back} alt="Back to resources" />Back to Resources</a>
         </Col>
         
         </Row>
         </Container>
        <Container className="articleSec">
        <Row>
         <Col className="headlineWrapper" xl={{span: 6 }}>
         <h1 className="headline">{page[0].title}</h1>
         </Col>
         <Col xl={{span: 6 }}>
         <img className="articleImg" src={page[0].featuredImage.url + "?fm=jpg&w=636&h=334&fit=fill&f=" + page[0].imageFocusArea +"&q=90"} alt={page[0].featuredImage.description} />
         </Col>
         </Row>
         </Container>
         <div className="articleSearchMobile">
         <Container>
         <h3>Summer Camp Search:</h3>
         <form>
          <div className="Icon-inside" id="location-search">
                  <input className="input-field" type="text" name="location" placeholder="Enter ZIP Code"
                  defaultValue={zipcodeSearch}
                  onChange={event => setZip(event.target.value)}
                  onFocus={(e) => e.target.value = ""}
                  
                  />
                  <i className="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
                  <Link className="btnSeearch" to={`/?l=${zip}&s=All`}>Search</Link>
                  </div>
         </form>
         </Container>
         </div>
         <Container className="articleSec articleMainSection">
         <Row className="mainArticle">
         <Col className="social" xl={{span: 1 }}>
          <p className="socialHeadline">share</p>
          <FacebookShareButton url={"https://summercampscout.com/resources/" + page[0].url } quote={'test quote'} hashtag="#summercampscout"><img className="socialShare" src={facebook} alt="Share on Facebook" /></FacebookShareButton>
          <TwitterShareButton url={"https://summercampscout.com/resources/" + page[0].url } hashtag="#summercampscout"><img className="socialShare" src={twitter} alt="Share on Twitter" /></TwitterShareButton>

         </Col>
         <Col xl={{span: 7 }}>
         
              <div className="articleContent">{documentToReactComponents(page[0].content.json, renderOptions)}</div>
          </Col>
         <Col xl={{span: 4 }}>
         <div className="articleSearch">
         <h3>Search for Summer Camps near me:</h3>
         <form>
          <div className="Icon-inside" id="location-search">
                  <input className="input-field" type="text" name="location" placeholder="Enter ZIP Code"
                  defaultValue={zipcodeSearch}
                  onChange={event => setZip(event.target.value)}
                  onFocus={(e) => e.target.value = ""}
                  
                  />
                  <i className="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
                  <Link className="btnSeearch" to={`/?l=${zip}&s=All`}>Search</Link>
                  </div>
         </form>
         </div>
        {promoOrgs ?
          <div className="campAd">
          <AdCard 
         name="card" 
         promoOrgs={promoOrgs}
         zip={zip} 
         lat={lat}
         lon={lon}
        img={page[0].featuredImage.url + "?fm=jpg&w=636&h=334&fit=fill&f=" + page[0].imageFocusArea +"&q=90"} alt={page[0].featuredImage.description} /> 
          </div>: <>  </> }

         <div className="articleEmail">
         <h3>Camp Notification</h3>
         <p>To get notified when summer camps near you become available, please enter your email address and zip code below.</p>
         <form id="campNotificationFormResource">
          <input type="email" name="email" className="input-field" placeholder="Email Address" />
          <div className="Icon-inside" id="location-resource">
                  <input className="input-field" type="text" name="zip" placeholder="Zip Code"
                  //defaultValue={zip}
                  onChange={event => setZip(event.target.value)}
                  onFocus={(e) => e.target.value = ""}
                  
                  />
                  <i className="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
                  </div>
                  <input className="formSubmit" type="submit" value="Submit" />
         </form>
         </div>
         </Col>
         </Row>
         
         </Container>
         <Container className="relatedNews">
          <Row className="row-flex">
          <Col className="relatedTitle" xl={{span: 12 }}>
          <h3>Related Posts</h3>
          </Col>
            {page[0].relatedArticles.items.map((card, index) => (
              <NEWSCARD key={index} index={index} title={card.title} url={card.url} featuredImage={card.featuredImage} imageFocusArea={card.imageFocusArea} description={card.description} content={card.content} index={index}  />
            ))}
          
            </Row>
         </Container>
         </Container>
        
       
        
      
      
        
      
        
        
        
          
        </div>
        )
         }else{
          return(<p>loading</p>)
         }
      
};

  export default NewsArticle;