import React from "react";
import { useState, useEffect, useInsertionEffect } from "react";
import ReactMarkdown from 'react-markdown'
// import newsimage from '../1200-630.png';
import link from '../link.svg';


const NEWSCARD = ({ index, title, url, content, description, featuredImage, imageFocusArea }) => {
  
  return (
    <a key={index} href={"/resources/" + url} className="news-card col-lg-4 col-md-6 col-sm-6">
        <div className="news-card-img">
        
          <img src={featuredImage.url + "?fm=jpg&w=400&h=210&fit=fill&f=" + imageFocusArea + "&q=95"} alt={featuredImage.description} />
        </div>
        <div className="news-card-data">
                <h2>{title}</h2>
                <p>{description}</p>
                <img src={link} alt="view article" alt={description}/>
        </div>
        
    </a>
  );
};

export default NEWSCARD;

