import { format, set } from 'date-fns'
import location from '../marker.svg';
import placeholder from '../placeholder.png';

function Advertisement(props) {
  
  return (
    <div className="campAd"> 
    <a id="resourcead" href={props.promoOrg.linkedFrom.campProvidersCollection.items[0].url} className="campAdCard" target="_blank">
     <div className="adcardimg"><img alt="Camp Logo"  src={props.promoOrg.linkedFrom.campProvidersCollection.items[0].image.url} /></div>
    <div className="twopromo">
      <h2 className="cardAdTitle">{props.promoOrg.linkedFrom.campProvidersCollection.items[0].name}</h2>
      <h3 className="campcardLocation"><img className="cardlocation" src={location} /> {props.promoOrg.linkedFrom.campProvidersCollection.items[0].name} | {props.promoOrg.city}, {props.promoOrg.state}</h3>
      <p className="addistance">X miles from {(props.zip == "" || props.zip == null) ? "Zip": props.zip} </p>
      
      
      
    </div>
      <div className="rightCard threepromo">
      
     
      </div> 
      <div className="promodescription fourpromo" lg="12">
        <p className="addescriptiontext">Villa Ventures is the largest summer camp in the Seattle area offering high-quality, engaging outdoor and indoor experiences designed for curious and creative minds.
        Explore our 31-acre campus featuring forests and gardens, a state-of-the-art athletic field, and a historic school building with art studios and science labs. </p>
        <p className="adpromotedtext">Advertisement</p>
      </div>
      </a>       
</div>
  )
}

export default Advertisement;
