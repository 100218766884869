import { Outlet, Link } from "react-router-dom";
import { useState, useEffect, useInsertionEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import PageHeader from '../components/pageHeader';


const query = `query{
  pagesCollection(where: { slug: "contact" }, limit: 1){
    items{
      title
      metaDescription
      content
      }
      
      
    }
  }` 

export default function Contact() {
  let [email, setEmail] = useState(false);
  const [page, setPage] = useState(null);

  const [fName,setFName] = useState("");
  const [lName,setLName] = useState("");
  const [emailName,setEmailName] = useState("");
  const[company,setCompany] = useState("");
  const [fMessage,setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
    navigate("/contact");
  }, []);
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validate = () => {
    var emailvalid = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailName));
    console.log(emailvalid);
    if(fName.length > 1 & lName.length > 1 & fMessage.length > 1 & emailvalid){
      return true;
    }else{
      return false;
    }
  };

  

  useEffect(() => {
    var isValid = validate();
    console.log(isValid + " is valid");
    setIsValid(isValid);
  }, [fName, lName, emailName, fMessage]);

  
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setPage(data.pagesCollection.items[0]);
        document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
  
      });
  }, []);

  useEffect(() => {
    document.title = "Contact | Summer Camp Scout";  
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setEmail(true);
    
}



if (!page) {
  return (
    <div className="wrapper">
      <main className="page-main">
      <div>Loading...</div>
      </main>
  </div>
  )
  }else {


    return (
      <div>
      <PageHeader title={"Contact US"}/>
      <div className="contactWrapper">
      
      <Container className="contact-content">
         <Col xl={{span: 6, offset:3 }}>
       
        <div className="pageContentContact">
        <ReactMarkdown>{page.content}</ReactMarkdown>
        </div>
        </Col>
        </Container>
         <Container className="form-content">
        <Col xl={{span: 6, offset:3 }}>
        
      
      
      {!email ? (
        <form id="contact-form" name="contact" onSubmit={onSubmitHandler}>
        <fieldset className="row gx-2">
        <div className="col-6 p-2">
          <input className="formInput" type="text" name="firstname" id="firstNameInput" placeholder="First Name" onChange={(e) => setFName(e.target.value)} />
          </div>
          <div className="col-6 p-2">
          <input className="formInput" type="text" name="lastname" id="lastNameInput" placeholder="Last Name" onChange={(e) => setLName(e.target.value)} />
          </div>
          <div className="col-6 p-2">
          <input className="formInput" type="text" name="email" id="emailInput" placeholder="Email" onChange={(e) => setEmailName(e.target.value)} />
          </div>
          <div className="col-6 p-2">
          <input className="formInput" type="text" name="company" id="companyInput" placeholder="Organization (optional)" onChange={(e) => setCompany(e.target.value)} />
          </div>
          <div className="p-2 col-12">
          <textarea className="formInput form-control" rows="7" type="text" name="message" id="messageInput" placeholder="Your Message ..." onChange={(e) => setMessage(e.target.value)} />
          </div>
          <div className="col-12 formBtnWrapper">
          <input id="contactBtn" type="submit" value="Send" className="small formInputBtn" disabled={!isValid} />
          </div>
          
        </fieldset>
    </form>
        ) : (
          <div className="formThankyou">
            <h3>The form was successfully submitted.</h3>
          <p>One of our team members will be in touch within 2 business days.</p>
          </div>
        )}
        </Col>

    </Container>
    </div>
      </div>
      
    );
        }
  }