import React, { useState } from "react";
import { format, set } from 'date-fns'
import location from '../whitepin.svg';
import placeholder from '../placeholder.png';
import circleImg from '../circle-check-solid-white.svg';
import { Link } from "react-router-dom";


function ProviderCard(props) {

    // let [total, setTotal] = useState(1);
    // let [providerData, setProviderData] = useState(null);

    function distance(lat1,
        lat2, lon1, lon2)
      {
      
      // The math module contains a function
      // named toRadians which converts from
      // degrees to radians.
      lon1 =  lon1 * Math.PI / 180;
      lon2 = lon2 * Math.PI / 180;
      lat1 = lat1 * Math.PI / 180;
      lat2 = lat2 * Math.PI / 180;
      
      // Haversine formula
      let dlon = lon2 - lon1;
      let dlat = lat2 - lat1;
      let a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2),2);
      
      let c = 2 * Math.asin(Math.sqrt(a));
      
      // Radius of earth in kilometers. Use 3956
      // for miles
      let r = 3956;
      
      // calculate the result
      let results = c * r;
      return(Math.round( results * 100 + Number.EPSILON ) / 100);
      }

      var id = props.promoOrgs.providerIds[props.promoNumber];
      var total = 0;
      for (let i = 0; i < props.promoOrgs.locationsAll.length; i++) {
        if (props.promoOrgs.locationsAll[i].cpid == id && props.promoOrgs.locationsAll[i].promoted == true)
          total += 1;
        
      }

      

      const [isListVisible, setListVisibility] = useState(false);

  const handleClick = () => {
    setListVisibility(!isListVisible);
  };

  const linkhandleClick = (idClick) => {
    // e.preventDefault();
    console.log('The link was clicked.', id);
    const dataClick = async () => {
        const data = await (
          
          await fetch(
            `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/record-click?id=${idClick}`
          )
        ).json();
        console.log('The link was clicked.');
      };
      dataClick();
  }
   
   
  return (
    <div>
    {props.promoOrgs.providers.map((providerData) => {
        if (providerData.cpid == id && providerData.promoted == true && (providerData.specialNeeds == props.specialNeeds || props.specialNeeds == false) ) {
            return (
      <div key={props.promoOrgs[props.promoNumber]} className="providerCardWrapper">    
        <div  id="provider-link"  className="campAdCard resourcard">
        
            
            <div className="providercardimg" key={"img-" + providerData.cpid}>
            { providerData.ytLink != "" ?
                            (<div className="video-responsive" key={"video-" + props.promoOrgs.providerIds[props.promoNumber]}>
                            <iframe
                            
                            width="853"
                            height="480"
                            src={`https://www.youtube.com/embed/${providerData.ytLink}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                            
                            />
                          </div>
                        ):
                            <Link  onClick={() => { linkhandleClick(providerData.cpid) }} to={providerData.websiteUrl + "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"} target="_blank">
                            <div className="providerImg" key={"img-" + props.promoOrgs.providerIds[props.promoNumber]} style={{  
                                backgroundImage: `url(https://static.summercampscout.com/media/providers/${providerData.image})`}}  />
                                </Link>
                            
                }
            
      
            </div>
       

            <div className="threepromo">
            
                        
                        
                        
                                <div  key={"title" + props.promoOrgs.providerIds[props.promoNumber]} className="providerHeader">
                        <Link onClick={() => { linkhandleClick(providerData.cpid) }}  to={providerData.websiteUrl + "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"} target="_blank"  className="cardAdTitle">{providerData.title}</Link>
                        <p>Ages: {providerData.minAge} to {providerData.maxAge}</p>
                        </div>
                   
                    
             {total > 1 &&
                <div>
                
            <h3 onClick={handleClick}className="promoLocationsList">{total}&nbsp; LOCATIONS {isListVisible ? "[-]" : "[+]"}</h3>
            
                {isListVisible && (
                    <ul className="promoLocationsListLinks">
                    {props.promoOrgs.locationsAll.map((locationData) => {
                        if (locationData.cpid == id && locationData.promoted == true) {
                            return (
                                <li key={locationData.rangeKey}><img className="cardlocation" src={location} />{locationData.description ? locationData.description : "not set"}&nbsp;
                                <span>&nbsp;({distance(props.lat, parseFloat(locationData.geoJson.split(',')[0]), props.lon, parseFloat(locationData.geoJson.split(',')[1]))} miles from {(props.zip == "" || props.zip == null) ? "Zip": props.zip})</span>
                                </li>
                            )
                        }
                    })}
                    </ul>
                  )}
                </div>
             }
             {!isListVisible && (
                <div className="mainLocation">
                <h3 className="providercardLocation"><img className="cardlocation" src={location} />{props.promoOrgs.locations[props.promoNumber].description} <span>&nbsp;(nearest location)</span></h3>
                <p className="addistance">{distance(props.lat, parseFloat(props.promoOrgs.locations[props.promoNumber].geoJson.split(',')[0]), props.lon, parseFloat(props.promoOrgs.locations[props.promoNumber].geoJson.split(',')[1]))} miles from {(props.zip == "" || props.zip == null) ? "Zip": props.zip} </p>
                </div>
            )}
            {providerData.specialNeeds == true ? (
                <p key={"special" + props.promoOrgs.providerIds[props.promoNumber]}className="specialNeeds"><img className="cardlocation" src={circleImg} /> Special Needs Friendly</p>
                ): <></>
            }
            
            
                    
                    {props.promoOrgs.providers.map((providerData) => {
                        if (providerData.cpid == id && providerData.promoted == true) {
                            return (
                                <div key={"desc-" + providerData.cpid} className="addescriptiontext">
                                <p >{providerData.description ? providerData.description : "not set"}</p>
                                    <div className="promoTextSection"> 
                                        <Link onClick={() => { linkhandleClick(providerData.cpid) }} id="pp-cta" to={providerData.websiteUrl + "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=providerPromo"} target="_blank" className="providerCardButton">CHECK AVAILABILITY</Link>   
                                        <p className="promotedtextHome">Promoted</p>
                                    </div>
                                </div>
            )
        }
    })}
            </div> 
      </div>  
      </div>
      
        )}
    })}
    </div>   
    
  )
}
export default ProviderCard;
