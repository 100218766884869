import { Outlet, Link } from "react-router-dom";
import { useState, useEffect, useInsertionEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col, Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import PageHeader from '../components/pageHeader';
import DemoCard from '../components/DemoCard';
import DemoPromoCard from "../components/DemoPromoCard";
import AdDemoCard from "../components/AdDemoCard";

const query = `query{
  pagesCollection(where: { slug: "summer-camp-listing-options" }, limit: 1){
    items{
      title
      metaDescription
      content
      componentsCollection{
        total
        items{
          __typename
          ... on ComponentLongTest {
            title
            content
          }
          ... on ComponentYouTubeVideo{
            youTubeId
          }
          
        }
        	
          
        }
      }
      }
  }` 

export default function Advertising() {
  let [email, setEmail] = useState(false);
  const [page, setPage] = useState(null);

  const [fName,setFName] = useState("");
  const [lName,setLName] = useState("");
  const [emailName,setEmailName] = useState("");
  const[company,setCompany] = useState("");
  const [fMessage,setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);


  const navigate = useNavigate();
//   useEffect(() => {
//     navigate("/contact");
//   }, []);
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validate = () => {
    var emailvalid = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailName));
    console.log(emailvalid);
    if(fName.length > 1 & lName.length > 1 & fMessage.length > 1 & emailvalid){
      return true;
    }else{
      return false;
    }
  };

  

  useEffect(() => {
    var isValid = validate();
    console.log(isValid + " is valid");
    setIsValid(isValid);
  }, [fName, lName, emailName, fMessage]);

  
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setPage(data.pagesCollection.items[0]);
        document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
  
      });
  }, []);

  useEffect(() => {
    document.title = "Listing Options | Summer Camp Scout";  
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setEmail(true);
    
}



if (!page) {
  return (
    <div className="wrapper">
      <main className="page-main">
      <div>Loading...</div>
      </main>
  </div>
  )
  }else {


    return (
      <div>
      <PageHeader title={"Advertising"}/>
      <div className="contactWrapper">
      
      <Container className="contact-content">
         <Col className="adTitle" md={{span: 12}} lg={{span:10, offset:1}}>
       
        <div className="pageContentAdvertising">
        <ReactMarkdown>{page.content}</ReactMarkdown>
        </div>
        </Col>
        </Container>
         <Container className="pageContentAdvertisingContent">
         <Row>
        <Col md={{span: 6}}>
        <ReactMarkdown>{page.componentsCollection.items[0].content}</ReactMarkdown>
        </Col>
        <Col md={{span: 6}}>
        <div className="video-responsive-advertise">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${page.componentsCollection.items[1].youTubeId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
  </div>
        
        </Col>
        </Row>
        </Container>
        <Container className="">
        <Col xl={{span: 10, offset: 1}} md={{span:12}}>
        <Table className="adTable rounded-corners" striped responsive bsPrefix>
        <thead className="tableHeader">
        <tr>
          <th></th>
            <th>Standard</th>
            <th>Premium</th>
            <th>Organization Promotion</th>
        </tr>
        </thead>
      <tbody>
      
      
        <tr className="tableDescription">
          <td className="tableBold">Benefits/Performance</td>
            <td>Camps appear in standard order on the search page.*</td>
            <td>Add an image or video and puts the camp listing above non-premium listings resulting in 100x+ impressions and 20x more clicks.</td>
            <td>Provider promotions are displayed between camp listings, within the specified search radius. Impression and click data for these promotions are accessible in the Provider Portal.</td>
        </tr>
        <tr className="campTableRow">
          <td className="tableBold">Camp Details</td>
            <td className="tableMark">X</td>
            <td className="tableMark">X</td>
            <td className="tableMark"></td>
        </tr>
        <tr className="campTableRow">
          <td className="tableBold">Description</td>
          <td className="tableMark"></td>
          <td className="tableMark">X</td>
          <td className="tableMark">X</td>
        </tr>
        <tr className="campTableRow">
          <td className="tableBold">Image/Short Video</td>
          <td className="tableMark"></td>
          <td className="tableMark">X</td>
          <td className="tableMark">X</td>
        </tr>
        <tr className="campTableRow">
          <td className="tableBold">Priority Placement</td>
          <td className="tableMark"></td>
          <td className="tableMark">X</td>
          <td className="tableMark">X</td>
        </tr>
        <tr className="campTableRow">
          <td className="tableBold">Covers Multiple Camps</td>
          <td className="tableMark"></td>
          <td className="tableMark"></td>
          <td className="tableMark">X</td>
        </tr>
        <tr className="campTableRow">
        <td className="tableBold">Directory Listing</td>
        <td className="tableMark"></td>
        <td className="tableMark"></td>
        <td className="tableMark">X</td>
      </tr>
        <tr className="campTableRow">
        <td className="tableBold">Resource Promotion</td>
        <td className="tableMark"></td>
        <td className="tableMark"></td>
        <td className="tableMark">X</td>
      </tr>
      <tr>
        <td className="tableBold">Performance Tracking</td>
        <td className="tableMark"></td>
        <td className="tableMark"></td>
        <td className="tableMark">X</td>
      </tr>
      <tr>
      <td className="tableBold">Cost</td>
      <td className="tableMark">$0</td>
      <td className="tableMark">$49</td>
      <td className="tableMark">$299</td>
    </tr>
    <tr>
    <td >Each Additional Location</td>
    <td className="tableMark"></td>
    <td className="tableMark"></td>
    <td className="tableMark">$99</td>
  </tr>
      </tbody>
    </Table>
        </Col>

    </Container>
    <Container className="ad-content adDemos">
    <Row>
    <Col xl={{span: 6, offset: 1 }} md={{span:7}} sm={{span:12}}>
    <h3 className="adCardMainHeader">Camp Listing Examples</h3>
    <DemoCard  promoType={"Standard Listing"} />
    <AdDemoCard promoType={"Premium Listing"}/>


    </Col>
    <Col xl={{span: 4}} md={{span:5}} sm={{span:12}}> 
    <h3 className="adCardMainHeader">Organization Promo Example</h3>
    <Col className="providerCard" lg="12">
    <DemoPromoCard />
    </Col>
    </Col>
    </Row>
    </Container>
    </div>
      </div>
      
    );
        }
  }