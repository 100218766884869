import '../App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate, 
  createSearchParams,
  useLocation,
  useSearchParams,
  
} from "react-router-dom";
import useGeolocation from "react-hook-geolocation";

import React, { useState, useEffect, useRef, ReactElement } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import Home from './home';
import NotFoundView from './NotFoundView';

import { hasSelectionSupport } from '@testing-library/user-event/dist/utils';

const query = `query {
  globalSettingsCollection(limit: 1){
      items{
        title
        notifications
        gtMid
        submitThankyou
        campListingPreviewCopy
        noCampsFound
        menuCollection{
          items{
                title
                slug
        		}
        
        }
        }
      }
    }` 


const tagManagerArgs = {
  gtmId: 'GTM-573BGJP'
}
// TagManager.initialize(tagManagerArgs)

export default function Main(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()
  // const geolocation = useGeolocation();
  let [lon, setLon] = useState(null);
  let [lat, setLat] = useState(null);
  let [selectDistance, setSelectDistance] = useState(25);
  let [zip, setZip] = useState(null);
  let [searchTerm, setSearchTerm] = useState("");
  let [citySearch, setCitySearch] = useState("");
  // let [camps, setCamps] = useState(null);
  let [globalData, setGlobalData] = useState(null);
  let [alert,setAlert] = useState(null);
  let [startDate, setStartDate] = useState(0);
  let [endDate, setEndDate] = useState(0);
  let [age, setAge] = useState(0);
  let [cost, setCost] = useState(100);
  let [zipcode,setZipcode] = useState(null);
  let [filter, setFilter] = useState(false);
  let [filterDescription, setFilterDescription] = useState(false);

  const [checkedDay, setCheckedDay] = useState(false);
  const [checkedNight, setCheckedNight] = useState(false);
  const [checkedAllDay, setCheckedAllDay] = useState(false);
  const [checkedAm, setCheckedAm] = useState(false);
  const [checkedPm, setCheckedPm] = useState(false);
  const [specialNeeds, setSpecialNeeds] = useState(false);
  const [locationTest, setLocationTest] = useState(null);

  

 


  let location = useLocation();
  const navigate = useNavigate();

  const searchCheckedDay = () => {
    if(checkedDay == false){
      console.log("false");
    setCheckedNight(false);
    setCheckedDay(true);
    }else{
      setCheckedDay(false);
    }
  }

  const searchCheckedNight = () => {
    if(checkedNight == false){
    setCheckedDay(false);
    setCheckedNight(true);
    }else{
      setCheckedNight(false);
      setCheckedNight(false);
    }
  }

  const searchCheckedAllday = () => {
    if(checkedAllDay == true){
      setCheckedAllDay(false)
    }else{
      setCheckedAllDay(true)
      setCheckedAm(false)
      setCheckedPm(false)
    }
    
  }
  const searchCheckedAm = () => {
    if(checkedAm == true){
      setCheckedAm(false)
    }else{
      setCheckedAm(true)
      setCheckedAllDay(false)
      setCheckedPm(false)
    }
    
  }
  const searchCheckedPm = () => {
    if(checkedPm == true){
      setCheckedPm(false)
    }else{
    setCheckedPm(true)
    setCheckedAm(false)
    setCheckedAllDay(false)
    }
  }

  const changeSpecialNeeds = () => {
    if(specialNeeds == true){
      setSpecialNeeds(false)
    }else{
      setSpecialNeeds(true)
    }
  }
  
  function testZip(newValue) {
    console.log(newValue)
  }

  function handleSearch(newValue) {
  setSearchTerm("");
  if(newValue == "") {
  }else{
  setSearchTerm(newValue.toUpperCase());
  }
}
function ageSet(newValue) {
  setAge(newValue);
}
function costSet(newValue) {
  setCost(newValue);
}
function setStart(newValue) {
  setStartDate(newValue);
}
function setEnd(newValue) {
  setEndDate(newValue);
}
function handleCity(newValue) {
  setCitySearch(newValue);
}

function handleLatChange(newValue) {
  setLat(newValue);

}

function handleLonChange(newValue) {
  setLon(newValue);
}
function zipcodeSet(newValue) {
  setZipcode(newValue);
}

function extractFromAdress(components, type){
  for (var i=0; i<components.length; i++)
      for (var j=0; j<components[i].types.length; j++)
          if (components[i].types[j]==type) return components[i].short_name;
  return "";
}

function clearFilter(){
  setEndDate(0);
  setStartDate(0);
  setAge(0);
  setCheckedDay(false);
  setCheckedNight(false);
}
function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

function updateParams(){
  const params = new URLSearchParams(location.search);
  const utm_source = params.get('utm_source');
  const utm_medium = params.get('utm_medium');
  const utm_campaign = params.get('utm_campaign');
  const utm_content = params.get('utm_content');
  const fbclid = params.get('fbclid');


  const options = {
  seearch: `l=${zipcode}&s=${searchTerm == '' ? "All" : searchTerm}${fbclid != null?'&fbclid=' + fbclid :''}${utm_content != null?'&utm_content=' + utm_content :''}${utm_source != null?'&utm_source=' + utm_source :''}${utm_source != null?'&utm_medium=' + utm_medium :''}${utm_campaign != null?'&utm_campaign=' + utm_campaign :''}`,
};



if(location.pathname != "/"){
  navigate(options, { replace: true });
}
}



useEffect(() => {

  var newLocations = window.location.pathname.toString().split('/');
  const filtered = newLocations.filter((num) => num !='');
  var locZip = searchParams.get('l');
  console.log("locZip: " + locZip)
  
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: Infinity
  };

  if(newLocations.length == 3){
    console.log("new locations: " + newLocations[1])
        window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${newLocations[1]+ ", " + newLocations[2]}&sensor=true&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,{
          method: "POST",
        }
          ).then((response) => response.json())
          .then((json) => {
            if(json.status == "OK"){
              console.log(json)
           
            var newLat = json.results[0].geometry.location.lat;
            var newLon = json.results[0].geometry.location.lng;
            
            setLat(parseFloat(newLat));
            setLon(parseFloat(newLon));
            setHasLoaded(true);
            
            }else{
              setLat(47.6085);
              setLon(-122.3364);
              setHasLoaded(true);
              //return redirect("/login");
            }
            
            
          });
        
  }
  else if(locZip != null){
    var newLoc = searchParams.get('l');
    console.log("URL LOCATION" + newLoc);
    window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${newLoc}&sensor=true&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,{
          method: "POST",
        }
          ).then((response) => response.json())
          .then((json) => {
            if(json.status == "OK"){
              console.log(json)
           
            var newLat = json.results[0].geometry.location.lat;
            var newLon = json.results[0].geometry.location.lng;
            
            setLat(parseFloat(newLat));
            setLon(parseFloat(newLon));
            setHasLoaded(true);
            // updateParams()
            
            }else{
              setLat(47.6085);
              setLon(-122.3364);
              setHasLoaded(true);
              //return redirect("/login");
              // updateParams()
            }
            
            
          });

  }
  
  else if(filtered.length >= 1){
    console.log(filtered)
    // setLat(47.6085);
    // setLon(-122.3364);
    
        window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${filtered[1]+ ", " +filtered[0]}&sensor=true&key=AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E`,{
          method: "POST",
        }
          ).then((response) => response.json())
          .then((json) => {
            if(json.status == "OK"){
              console.log(json)
           
            var newLat = json.results[0].geometry.location.lat;
            var newLon = json.results[0].geometry.location.lng;
            
            setLat(parseFloat(newLat));
            setLon(parseFloat(newLon));
            setHasLoaded(true);
            
            }else{
              setLat(47.6085);
              setLon(-122.3364);
              setHasLoaded(true);
              //return redirect("/login");
            }
            
            
          });
        
    
  }else{
    
  navigator.geolocation.getCurrentPosition(function(position) {
      setLat(position.coords.latitude);
      setLon(position.coords.longitude);
    console.log("geolocation set : " + position)
      setHasLoaded(true);
            
    
  },function(error) {
    fetch('https://ipinfo.io?token=be0eeb1f8c4e01')
  .then(response => response.json())
  .then(data => {
    var location = data.loc.split(",");
  
      setLat(location[0]);
      setLon(location[1]);
      setHasLoaded(true);
  })
  .catch(error => console.error('Error:', error));
      
  }, options);
}
}, []);

useEffect(() => {
  window
    .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authenticate the request
        Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
      },
      // send the GraphQL query
      body: JSON.stringify({ query }),
    })
    .then((response) => response.json())
    .then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }

      // rerender the entire component with new data
      // console.log(data.globalSettingsCollection.items[0]);
      setGlobalData(data.globalSettingsCollection.items[0]);
      setAlert(data.globalSettingsCollection.items[0].notifications)
      
    });
}, []);

useEffect(() => {
  const script = document.createElement('script');
  script.src='https://js.hs-scripts.com/23548397.js';
  script.id="hs-script-loader" 
  document.body.appendChild(script);
})  



  return (
   
      <div>
      <Header hasLoaded={hasLoaded} lat={lat} lon={lon} alert={alert} newLat={handleLatChange}  newLon={handleLonChange} filter={filter} zip={zip} setZip={setZip} filterDescription={filterDescription} setFilterDescription={setFilterDescription} setFilter={setFilter} setZipcode={setZipcode} zipcode={zipcode} searchTerm={searchTerm} newSearch={handleSearch} citySearch={handleCity} globalData={globalData}/>
      <Home specialNeeds={specialNeeds} changeSpecialNeeds={changeSpecialNeeds} globalData={globalData} hasLoaded={hasLoaded} alert={alert} zipcode={zipcode} setZip={setZip} searchCheckedDay={searchCheckedDay} searchCheckedAllday={searchCheckedAllday} searchCheckedAm={searchCheckedAm} searchCheckedPm={searchCheckedPm} checkedAllDay={checkedAllDay} checkedAm={checkedAm} checkedPm={checkedPm} checkedDay={checkedDay} searchCheckedNight={searchCheckedNight} checkedNight={checkedNight} lat={lat} lon={lon} selectDistance={selectDistance} setSelectDistance={setSelectDistance} clearFilter={clearFilter} filter={filter} filterDescription={filterDescription} setFilterDescription={setFilterDescription} setFilter={setFilter} searchTerm={searchTerm} citySearch={citySearch} age={age} cost={cost} costSet={costSet} ageSet={ageSet} setStart={setStart} setEnd={setEnd} startDate={startDate} endDate={endDate} />
       
      </div>
     
  );
}
