import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect, useInsertionEffect } from "react";
import PageHeader from '../components/pageHeader';
import ReactMarkdown from 'react-markdown'

import NEWSCARD from "../components/newscard";

const queryArticles = `query{
    articleCollection(order: [publishDate_DESC]){
      items{
        title
        url
        description
        imageFocusArea
        categoryCollection{
          items{
                title
                slug
        		}
        
        }
        featuredImage {
          title
          description
          contentType
          fileName
          size
          url
          width
          height
        }
        }
      }
    }` 

    const queryPage = `query{
      pagesCollection(
        limit: 1
        where: {slug: "resources"}){
        items{
          title
          slug
          metaDescription
          content
          }
        }
      }` 

const News = (props) => {
    const [page, setPage] = useState(null);
    const [index, setIndex] = useState(0);
    const [pageData, setPageData] = useState(null);
    const [pageCategory, setPageCategory] = useState('All');
  useEffect(() => {
    var query = queryArticles
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setPage(data.articleCollection.items);
        //document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
      });
  }, []);

  useEffect(() => {
    var query = queryPage
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setPageData(data.pagesCollection.items);
        document.querySelector('meta[property="og:title"]').setAttribute("content", data.pagesCollection.items[0].title);
        document.querySelector('meta[property="og:description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
        document.querySelector('meta[property="og:url"]').setAttribute("content",  "https//:summercampscout.com/resources");
        document.querySelector('meta[name="twitter:site"]').setAttribute("content",  "https//:summercampscout.com/resources");
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", data.pagesCollection.items[0].title);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
        document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
      });
  }, []);

  useEffect(() => {
    // document.title = pageCategory + "Resources | Summer Camp Scout";  
  }, []);

  useEffect(() => {
    var newLocations = window.location.pathname.toString().split('/');
    console.log(newLocations);
    if(newLocations.length == 4){
      setPageCategory(newLocations[3]);
      if(newLocations[3] == "parents"){
        document.title = "Parent Resources | Summer Camp Scout";
      }else if(newLocations[3] == "providers"){
        document.title =  "Provider Resources | Summer Camp Scout";
      }else if(newLocations[3] == "news-coverage"){
        document.title = "News Coverage | Summer Camp Scout";
      }else{
        document.title = "Resources | Summer Camp Scout";
      }
      
    }else{
      setPageCategory("All");
      document.title = "Resources | Summer Camp Scout";
    }
    
  }, []);
    

      const toggleFAQ = index => {
        setIndex(index);
      };

      
        if (pageData != null && page != null) {
        return (
        <div>
       
        <PageHeader title={"Resources"}/>
        <Container fluid className="news-header">
          <Container className="news-header-copy">
            <Row>
            {props.globalData ?
            <Col xl={{span: 10 }}>
            
            
            
          {pageCategory == "All" ? (
            <div>
            <h1 className="pageTitleMobile">RESOURCES</h1>
            <ReactMarkdown>{pageData[0].content}</ReactMarkdown>
            </div>
          ) : (
            <div>
            {props.globalData.resourcesMenuCollection.items.map((menu) => {
              if(menu.slug === pageCategory && pageCategory) { 
                  return (
                    <div>
                    <h1 className="pageTitleMobile">RESOURCES: {menu.title.toUpperCase()} </h1>
                    <ReactMarkdown>{menu.description}</ReactMarkdown>
                    </div>
                  )
              }
            })
          }
          </div>
          )
          }
            
            </Col>
            :<></>
          }
            </Row>
          </Container>
        </Container>
          <Container className="resources-content">
         <Col xl={{span: 12, offset:0 }}>
        <div className="pageContent container">
        <div>
        {props.globalData ?
        <ul className="news-nav">
        <li><a className={pageCategory == "All" ? "newsActive" : ""} href="/resources">All</a></li>
        {props.globalData.resourcesMenuCollection.items.map((menu) => {
          return (
            <li><a className={pageCategory == menu.slug ? "newsActive" : ""} href={"/resources/category/" + menu.slug}>{menu.title}</a></li>
          )
        })
      }
        </ul>
        :<></>
    }
        </div>
        <div className="faqs row">
            {page.map((card, index) => {
              if(pageCategory == 'All' || card.categoryCollection.items[0].slug == pageCategory) {
              return(
              <NEWSCARD key={index} index={index} title={card.title} url={card.url} featuredImage={card.featuredImage} imageFocusArea={card.imageFocusArea} description={card.description} content={card.content} index={index}  />
            )
          }
            })}
            
          </div>
        </div>
        </Col>
        </Container>
         
          
        </div>
        )
         }else{
          return(<p>loading</p>)
         }
      
};

  export default News;