import { useNavigate, createSearchParams, useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import sliderIcon from '../slider-icon.png';
import Card from '../components/card';
import Advertisement from '../components/Advertisement';
import PromoCard from '../components/promocard';
import SimpleMap from "../components/map";
import closeBtn from '../close.svg';
import arrow from '../arrow.png';
import moment from 'moment'
import { format, set } from 'date-fns'
import Lottie from "react-lottie";
import * as animationData from "../location-pin.json";
import ProviderCard from '../components/ProviderCard';



const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function extractFromAdress(components, type){
  for (var i=0; i<components.length; i++)
      for (var j=0; j<components[i].types.length; j++)
          if (components[i].types[j]==type) return components[i].short_name;
  return "";
}





export default function ArchiveHome(props) {
  
  
  let [mapWidth, setMapWidth] = useState(false);
  
  // let [filter, setFilter] = useState(false);
  let [camps, setCamps] = useState(null);
  let[campsFound, setCampsFound] = useState(0)
  let [promoOrgs, setPromoOrgs] = useState(null);
  const zipInput = useRef(null);
  const searchInput = useRef(null);
  
  let [citySearch, setCitySearch] = useState("");
  let [zipcodeSearch, setZipcodeSearch] = useState("");
  let [stateSearch, setStateSearch] = useState("");
  
  let [providers, setProviders] = useState(null);
  let[locations, setLocations] = useState(null);
  let [distance, setDistance] = useState(50);

  useEffect(() => {
    // fetch data
   
    const dataFetch = async () => {
      const data = await (
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-providers`
        )
      ).json();

      // set state when the data received
      console.log(data);
      setProviders(data);
    };

    dataFetch();
  }, [props.lat, props.lon]);

  useEffect(() => {
    // fetch data
    
    const dataFetchOne = async () => {
      const data = await (
        
        await fetch(
          `https://il7z2ikyp4.execute-api.us-west-2.amazonaws.com/development/api/all-locations?distance=100&lat=${props.lat}&lon=${props.lon}`
        )
      ).json();
  
      // set state when the data received
      //setCampsFound(data.length)
      console.log(data);
      setLocations(data);
    };
    if(props.lat != null){
    dataFetchOne();
    }

  }, [props.lat, props.lon]);


  const navigate = useNavigate();
  let location = useLocation();
  const { userName } = useParams();

  const mapLarge = event => {
    if(mapWidth == false){
      setMapWidth(true);
    }else{
      setMapWidth(false);
    }  
  };
  let currentData = moment().toISOString()
  

  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleChangeDay = () => props.searchCheckedDay();
  const handleChangeNight = () => props.searchCheckedNight();
  const handleChangeAllDay = () => props.searchCheckedAllday();
  const handleChangeAm = () => props.searchCheckedAm();
  const handleChangePm = () => props.searchCheckedPm();

  const incrementStart = (c) => props.setStart((c));
  const incrementEnd = (c) => props.setEnd((c));
  const incrementCost = (c) => props.costSet((c));
  const incrementAge = (c) => {
    if(c == ''){
      props.ageSet((0))
    }else{
      props.ageSet((c))
    }
   
  };

  

  function paginated_fetch( page = 0, previousResponse = []) {
    
    const query = `query {
      campCollection(skip: ${page}, limit: 1000, order: [startDate_ASC],where:{address_within_circle:{lat:${props.lat}, lon: ${props.lon}, radius:${parseInt(props.selectDistance)}}}){
        items{
          sys{id}
          title
          organization
          ageMax
          ageMin
          dayOfTheWeek
          locationDescription
          type
          cost
          startDate
          endDateTime
          registrationOpens
          address {
            lat
            lon
          }
          promoteListing
          campWebsite
          campDescription
          campImage {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
    }`

    return window.fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`,{
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({query})
    })
      .then(response => response.json())
      .then(newResponse => {
        //console.log(newResponse);
        var data = newResponse["data"]["campCollection"]["items"];
       //console.log(data);
        const fulllist = previousResponse.concat(data);
        const response = fulllist;
       
        if (data.length >= 1000) {
          page = page + 1000;
  
          return paginated_fetch(page, response);
        }
       // console.log(response);
        
        return response;
      });
  }


//   function getPromotions() {
    
//     const query = `query {
//       campProviderLocationCollection(limit: 1000, where:{location_within_circle:{lat:${props.lat}, lon: ${props.lon}, radius:1000}}){
//         items{
//           city
//           state
//           zip
//           location {
//             lat
//             lon
//           }
//           linkedFrom {
//             campProvidersCollection(limit: 4){
//             items{
//                 name
//                 url
//                 image{
//                   title
//                   description
//                   contentType
//                   fileName
                
//                   url
                 
//                 }
//             }
//           }
//         }
//         }
//       }
//     }`

//     return window.fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`,{
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({query})
//     })
//       .then(response => response.json())
//       .then(newResponse => {
//         console.log(newResponse)
//         var data = newResponse["data"]["campProviderLocationCollection"]["items"];
//        console.log(data);
//         return data;
//       });
//   }

  
  useEffect(() => {
    if(props.hasLoaded) {
    paginated_fetch().then(json => {
      console.log(json)
      json.sort((a, b) => b.promoteListing - a.promoteListing);
      json.sort((a, b) => b.title - a.title);
      setCamps(json);
      console.log(json)
      setCampsFound('')
      
    })
  }
}, [props.lon, props.lat, props.selectDistance, props.hasLoaded]);


// useEffect(() => {
//   if(props.hasLoaded) {
//   getPromotions().then(json => {
//     setPromoOrgs(json);
//   })
// }
// }, [props.lon, props.lat, props.hasLoaded]);



useEffect(() => {
if(camps != null){
  setCampsFound(0)

  camps.map((camp) => {
    console.log(camp.sys.id)
    let testtimeOne = format(new Date(camp.startDate), 'H')
    let testtimeTwo = format(new Date(camp.endDateTime), 'H')
    let newDate = (Number(testtimeTwo) - Number(testtimeOne))
    if(
      (camp.title.toUpperCase().includes(props.searchTerm) || camp.organization.toUpperCase().includes(props.searchTerm))
    && ((moment(props.startDate).format('MM/DD/YYYY') <= moment(camp.startDate).format('MM/DD/YYYY') || props.startDate == 0) && (moment(props.endDate).format('MM/DD/YYYY') >= moment(camp.endDateTime).format('MM/DD/YYYY') || props.endDate == 0))
    && ((camp.ageMin <= props.age && camp.ageMax >= props.age) || props.age == 0)
    && (camp.cost <= props.cost || props.cost == 100)
    && ((camp.type == 'Overnight' && props.checkedNight == true) || (camp.type == 'Day' && props.checkedDay == true) || (props.checkedDay == false && props.checkedNight == false))
    &&(((testtimeOne <= 10) && (newDate <=5) && (props.checkedAm == true)) || ((newDate > 5) && (props.checkedAllDay == true)) || ((props.checkedPm == true) && (testtimeOne > 10)) || ((props.checkedAm == false) && (props.checkedPm == false) && (props.checkedAllDay == false)))
    ){
      // console.log(moment(props.startDate).format('MM/DD/YYYY'));
      // console.log(moment(camp.endDateTime).format('MM/DD/YYYY'))
      var add = document.getElementById(camp.sys.id);
      // add.style.display = 'grid';
      // add.classList.add("active");
      
    }else{
      var remove = document.getElementById(camp.sys.id);
      // remove.style.display = 'none';
      // remove.classList.remove("active");
    }
  })

const collection = document.getElementsByClassName("active");
setCampsFound(camps.length);
}


const params = new URLSearchParams(location.search);
const utm_source = params.get('utm_source');
const utm_medium = params.get('utm_medium');
const utm_campaign = params.get('utm_campaign');
const utm_content = params.get('utm_content');
const fbclid = params.get('fbclid');

const options = {
  pathname: `?l=${props.zipcode}&s=${props.searchTerm == '' ? "All" : props.searchTerm}${fbclid != null?'&fbclid=' + fbclid :''}${utm_content != null?'&utm_content=' + utm_content :''}${utm_source != null?'&utm_source=' + utm_source :''}${utm_source != null?'&utm_medium=' + utm_medium :''}${utm_campaign != null?'&utm_campaign=' + utm_campaign :''}`,
  search: `?${createSearchParams()}`,
};



if(location.pathname == "/"){
  navigate(options, { replace: true });
}else{
  // var newLocations = location.pathname.split( '/' );
  // if(newLocations.length > 1){
  //   setZipTest(newLocations[2])
  //   console.log(newLocations)
  // }else{
  //   setZipTest(newLocations[1])
  //   console.log(newLocations);
  // }
}

}, [props.searchTerm, props.age, props.cost, props.startDate, props.endDate, props.zipcode, campsFound, props.checkedNight, props.checkedDay, props.checkedAllDay,props.checkedPm, props.checkedAm]);

function showAllCamps(e) {
  e.preventDefault();
  console.log('You clicked submit.');
  props.setSelectDistance(parseInt(2000));
}

function setFilterSettings(filterSetting){
  props.setFilter(filterSetting)
  props.setFilterDescription(true)
}
function filter(){
  props.clearFilter()
  var removeStart = document.getElementById("startdate");
  var removeEnd = document.getElementById("enddate");
  var removeAge = document.getElementById("age");
  removeStart.value = ""
  removeEnd.value = ""
  removeAge.value = ""
}

  const onChange = (event) => {
    const value = event.target.value;
    props.setSelectDistance(parseInt(value));
  };

if(!camps || locations == null || providers == null) return <div className="loading"><Container>
<Lottie options={defaultOptions}
              height={100}
              width={100}
             />
             <h1>Retrieving location data...</h1>
              </Container></div>;
    return (
      <div>
      
      <Container fluid className={`${props.alert ? 'main-content' : 'main-content noalert'}`}>
        <Row>
          <Col lg="2" md="4" className="filterwrapper">

            <div className="mainfilter">
              <div className="filter-wrapper-top">
                <h3 className="filterTitleDistance">Search Radius</h3>
                <select defaultValue={'DEFAULT'} onChange={onChange} id="distanceSelect" className="distance">
                <option value="5">3 miles</option>
                <option value="8">5 miles</option>
                <option value="16" value="DEFAULT">10 miles</option>
                <option value="40">25 miles</option>
                <option  value="20000">All</option>
              </select>
          </div>
          <div>

          
    </div>
            
            <div className="filter-wrapper filterHide" className={props.filter ? 'filter-wrapper filterShow' : 'filter-wrapper filterHide'}>
              <div className="filterTitleMobile" >
              <h3 className="filterTitle">Filter</h3>
              {(props.startDate != 0 || props.endDate != 0 || props.age != 0) &&
              <button className="clearAll" onClick={() => filter()}>Clear All</button>
              }
              <button type="button" className="filterCloseBtn" onClick={() => setFilterSettings(!props.filter)}><img className="closeFilterBtn" src={closeBtn} /></button>
              </div>
              <div className="filterSectionAge">
                <label className="ageLabel">Age of Child <span>(in years)</span></label>
                <input className="filter age" type="number" onChange={event => incrementAge(event.target.value)} id="age" />
              </div>
              <div className="filterSectionType">
                <label className="typeLabel">Camp Type</label>
                  <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedDay}
                      onChange={handleChangeDay}
                    />
                    Day
                    </label>
                    <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedNight}
                      onChange={handleChangeNight}
                    />
                    Overnight
                    </label>
                    
              

          
              
             
            </div>
              <div className="filterSection">
                <div className="dateSection">
                  <label className="datelabel">Start Date</label>
                  <input className="filter startdate form-control" placeholder="Start Date" type="date"  onChange={event => incrementStart(event.target.value)} onFocus={(e) => e.target.type = 'date'}  id="startdate" /> 
                  </div>
                  <div className="dateSection">
                  <label className="datelabel">End Date </label>
                  <input className="filter enddate form-control" placeholder="End Date" type="date"  onChange={event => incrementEnd(event.target.value)} onFocus={(e) => e.target.type = 'date'}  id="enddate" />
                </div>
              </div>
             

             


              <div className="filterSectionType">
              <label className="typeLabel">Times</label>
                  <label className="checkLabel">
                    
                    <input
                      type="checkbox"
                      checked={props.checkedAllDay}
                      onChange={handleChangeAllDay}
                    />
                    All Day
                    </label>
                    <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedAm}
                      onChange={handleChangeAm}
                    />
                    AM
                    </label>

                    <label className="checkLabel">
                    <input
                      type="checkbox"
                      checked={props.checkedPm}
                      onChange={handleChangePm}
                    />
                    PM
                    </label>
                </div>
            </div>
            </div>           
            
         
          </Col>
          <Col lg={mapWidth == true ? 4 : 6} md="8">
            <Row>
              <div className="campFoundWrapper">
                {!props.filterDescription ? <img className="arrow" src={arrow} /> : <></>}
                {props.filterDescription}
                <p className="campFoundMobile">Camps Found: <span>{campsFound}</span></p>
                {!props.filterDescription ?<p className="filterDirections">Use filters and search to narrow results.</p> : <></>}
                </div>
              <div className="breadcrumbSection">
              <p>{props.citySearch}&nbsp; &nbsp;>&nbsp;&nbsp; {props.searchTerm}</p>
              <h1 className="headline">2023 Archive: {props.searchTerm ? props.searchTerm : "SUMMER "} CAMPS in {props.citySearch}</h1>
              </div>
             
              <p className="campFound">Camps Found: <span>{campsFound}</span></p>
             {promoOrgs != null ? promoOrgs.map((promoOrg) => {
              //console.log(promoOrg)
              return(
                <Advertisement key={promoOrg} promoOrg={promoOrg} zip={props.zipcode} />
              )
             })
              : <></>
            }
            
            {camps.map((camp, index) => {
              if(index === 3){
                return (
                  <div className="p-0" key={locations[0].rangeKey}>
                        <Col className="providerCard" lg="12" key={locations[0].rangeKey}>
                          <ProviderCard lat={props.lat} lon={props.lon} location={locations[0]} zip={props.zipcode} providers={providers} />
                      </Col>
                   
                  <Col className={`campCardSection ${camp.promoteListing ? 'promo' : ''}`} lg="12" key={camp.sys.id}>
                  
                    {camp.promoteListing ? 
                    <PromoCard zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}  campDescription={camp.campDescription} campImage={camp.campImage.url} campWebsite={camp.campWebsite}/> : 
                    <Card name="card" zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}campWebsite={camp.campWebsite} />}
                </Col>
                </div>
              );
              }else if(index === 7){
                
                return (
                  <div className="p-0" key={locations[1].rangeKey}>
                        <Col className="providerCard" lg="12" key={locations[1].rangeKey}>
                          <ProviderCard lat={props.lat} lon={props.lon} location={locations[1]} zip={props.zipcode} providers={providers} />
                      </Col>
                   
                  <Col className={`campCardSection ${camp.promoteListing ? 'promo' : ''}`} lg="12" key={camp.sys.id}>
                  
                    {camp.promoteListing ? 
                    <PromoCard zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}  campDescription={camp.campDescription} campImage={camp.campImage.url} campWebsite={camp.campWebsite}/> : 
                    <Card name="card" zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}campWebsite={camp.campWebsite} />}
                </Col>
                </div>
              );

            }else if(index === 12){
                
              return (
                <div className="p-0" key={locations[2].rangeKey}>
                      <Col className="providerCard" lg="12" key={locations[2].rangeKey}>
                        <ProviderCard lat={props.lat} lon={props.lon} location={locations[2]} zip={props.zipcode} providers={providers} />
                    </Col>
                 
                <Col className={`campCardSection ${camp.promoteListing ? 'promo' : ''}`} lg="12" key={camp.sys.id}>
                
                  {camp.promoteListing ? 
                  <PromoCard zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}  campDescription={camp.campDescription} campImage={camp.campImage.url} campWebsite={camp.campWebsite}/> : 
                  <Card name="card" zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}campWebsite={camp.campWebsite} />}
              </Col>
              </div>
            );
            

              }else{
                return (
                    <Col className={`campCardSection ${camp.promoteListing ? 'promo' : ''}`} lg="12" key={camp.sys.id}>
                    
                      {camp.promoteListing ? 
                      <PromoCard zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}  campDescription={camp.campDescription} campImage={camp.campImage.url} campWebsite={camp.campWebsite}/> : 
                      <Card name="card" zip={props.zipcode} loclat={props.lat} loclon={props.lon} registrationOpens={false} campid={camp.sys.id} title={camp.title} organization={camp.organization} ageMax={camp.ageMax} dayOfTheWeek={camp.dayOfTheWeek} ageMin={camp.ageMin} locationDescription={camp.locationDescription} lat={camp.address.lat} lon={camp.address.lon} startDate={camp.startDate} endDateTime={camp.endDateTime} type={camp.type} cost={camp.cost}campWebsite={camp.campWebsite} />}
                  </Col>
                );
                      }
              })}
              {campsFound == 0 ? 
                <Col lg="12">
                <div className="no-results">
                <h2>No Summer Camps found near <span>{props.zipcode}</span></h2>
                <p>No camps have been found on our platform near your location. Be the first to submit camps (<span className="linkBlue">it’s FREE!</span>), explore all camps across the country or <a href="/contact" className="linkOrange">contact us</a> for more information or camp opportunities.</p>
                <button className="blueBtn" onClick={showAllCamps}> Show All Camps</button>
                <a href="https://provider.summercampscout.com/" className="orangeBtn">Submit Camps</a>

                <div className="noCampEmail">
                <p><span className="linkBlue">To get notified</span> when summer camps near you become available, please enter your email below.</p>
                <div className="noCampEmailForm">
        
                  <form id="campNotificationFormHome">
                    <div className="Icon-inside" id="home-form">
                            <input className="input-field" type="email" name="email" placeholder="Email address"
                            />
                            <input className="input-field" type="hidden" name="zip" placeholder="zip" value={props.zipcode}
                            />
                            <button>Submit</button>
                            </div>
                  </form>
                  </div>
                </div>
                </div>
                
                </Col>
                : <></>}
            
            </Row>
            </Col>
            <Col className="mapsection" lg={mapWidth == true ? 6 : 4} >
            <button className="expand" onClick={mapLarge}>
            Expand Map
            </button>


          <SimpleMap camps={camps} lat={props.lat} lon={props.lon} searchTerm={props.searchTerm} startDate={props.startDate} endDate={props.endDate} age={props.age} cost={props.cost}/>
          
          </Col>
        </Row>
      </Container>
      
      </div>
    );
  }