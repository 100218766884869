
import React from "react";
import FAQ from "../components/faq";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect, useInsertionEffect } from "react";
import PageHeader from '../components/pageHeader';

const query = `query{
  faqCollection{
    items{
      question
      answer
      }
    }
  }` 

  

const FAQS = () => {
  const [page, setPage] = useState(null);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        console.log(data);
        setPage(data.faqCollection.items);
  
      });
  }, []);

    

      const toggleFAQ = index => {
        setIndex(index);
      };

      
        if (page) {
        return (
        <div>
        <PageHeader title={"Faq's"}/>
          <Container className="contact-content">
         <Col xl={{span: 8, offset:2 }}>
       
        <div className="pageContent">
        <h1 className="headline">Frequently Asked Questions</h1>
              
        <div className="faqs">
            {page.map((faq, valindex) => (
              <FAQ faq={faq.question} answer={faq.answer} index={index} valindex={valindex} key={valindex} toggleFAQ={toggleFAQ} />
            ))}
          </div>
        </div>
        </Col>
        </Container>
         
          
        </div>
        )
         }else{
          return(<p>loading</p>)
         }
      
};

export default FAQS;
