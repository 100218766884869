import { format, set } from 'date-fns'
import location from '../whitepin.svg';
import placeholder from '../placeholder.png';
import promoImg from '../provider-demo.jpeg';
import circleImg from '../circle-check-solid-white.svg';



function DemoPromoCard(props) {
  
  return (
    <div className="providerCardWrapper">
      <a id="" href="#" className="campAdCard resourcardDemo" target="_blank">
            
            <div className="providercardimgDemo">
            
                            <div className="providerImgDemo" style={{  
                                backgroundImage: `url(https://static.summercampscout.com/media/providers/Kidz-Academy_PACSCI_535272324.jpg)`}}  />
            </div>
            <div className="threepromo">
            
                        <div className="providerHeader">
                        <h2 className="cardAdTitle">Kidz Academy</h2><p>Ages: 5 to 14</p>
                        </div>
               
            
            <h3 className="providercardLocation"><img className="cardlocation" src={location} />Pacific Science Center</h3>
            <p className="addistance">3.14 miles from 98115</p>
            
                    <p className="specialNeeds"><img className="cardlocation" src={circleImg} /> Special Needs Friendly</p>
                 
            <p  className="addescriptiontext">Kidz Academy's award-winning camps offer students the opportunity to explore, experiment, and ignite their curiosity through a range of innovative topics. Camps for Curious Minds offers over 100 exciting camp experiences for kids in grades PreK-8 across multiple locations, including Seattle and Bellevue.</p>
                    
            </div> 
      </a>  

        
                </div>
  )
}

export default DemoPromoCard;
