import { format, set } from 'date-fns'
import location from '../marker.svg';
import { First } from 'react-bootstrap/esm/PageItem';

function DemoCard(props) {
  
  


  
  return (
    <div>
    <button className="promoBtnType">{props.promoType}</button>
    <div className="campcard">  
                       
      <div className="one">
      <h2 className="blue cardTitle">Superheros <span>| Kidz Academy</span></h2>
      <h3 className="campcardLocation"><img className="cardlocation" src={location} />Pacific Science Center</h3>
      <p className="carddistance">3.14 miles from 98115</p> 
      </div>
      <div className="centerCard two">
      <p className="camp-date"><span>Aug 07 - Aug 11</span></p>
      
       <p>Mon - First, 9:00AM - 4:00PM</p>
      
     
      <p className="registration">Registration: <span className="green">Open</span></p>
     
  
      </div>
      <div className="rightCard three">
        <p className="camp-age">Ages: 6 - 8</p>
        <p className="campcost">$579</p>
        
        </div>
      </div>
      </div>
  );
}

export default DemoCard;
