import { Outlet, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from '../components/pageHeader';

const query = `query{
  pagesCollection(where: { slug: "cookie-policy" }, limit: 1){
    items{
      title
      metaDescription
      content
        
      }
      
      
    }
  }` 

export default function Cookies() {

  const [page, setPage] = useState(null);

  

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPage(data.pagesCollection.items[0]);
        document.querySelector('meta[name="description"]').setAttribute("content", data.pagesCollection.items[0].metaDescription);
      });
  }, []);

    useEffect(() => {
        document.title = "Cookie Settings | Summer Camp Scout";  
      }, []);

      if (!page) {
    return (
      <div className="wrapper">
        <main className="page-main">
        <div>Loading...</div>
        </main>
    </div>
    )
    }else {
      return (
        <div>
        <PageHeader title={"Cookies"}/>
        <Container className="main-content">
         <Col xl={{span: 10, offset:1 }}>
       <div className="pageContent">
       <ReactMarkdown>{page.content}</ReactMarkdown>
       </div>
       </Col>
   </Container>
   </div>
      )
    }
    
  }