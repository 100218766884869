import React, { useState, useEffect, useRef, ReactElement } from "react";
import GoogleMapReact from 'google-map-react';
// import useGeolocation from "react-hook-geolocation";
import Marker from '../Marker';
import moment from 'moment'

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: false
  };
}

function SimpleMap(props){
    // const geolocation = useGeolocation();
    let [currentlat, setCurrentLat] = useState(null);
    let [currentlon, setCurrentLon] = useState(null);
    let [search, setSearch] = useState("All");
  
      var defaultProps = {
        center: {
          lat: parseFloat(props.lat),
          lng: parseFloat(props.lon)
        },
        zoom: 11
      };
  
      useEffect(()=>{
  
        setCurrentLat(props.lat)
        setCurrentLon(props.lon);
        setSearch(props.searchTerm);
       },[props.lat, props.lon, props.searchTerm, props.camps]);
    
      return (
        // Important! Always set the container height explicitly
        <div className="mapDiv">
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAobxMrmZU6_v1qYre8C3MBs_bGRCYiP3E" }}
            center={[props.lat, props.lon]}
            defaultZoom={defaultProps.zoom}
            options={createMapOptions}
          >
  
          {props.camps.map((camps) => {
             if(
              (camps.title.toUpperCase().includes(props.searchTerm) || 
              camps.organization.toUpperCase().includes(props.searchTerm) || 
              props.searchTerm == "All" || 
              props.searchTerm == "ALL") &&
              ((moment(props.startDate).format('MM/DD/YYYY') <= moment(camps.startDate).format('MM/DD/YYYY') || 
              props.startDate == 0) && 
              (moment(props.endDate).format('MM/DD/YYYY') >= moment(camps.endDateTime).format('MM/DD/YYYY') ||
               props.endDate == 0))
              && ((camps.ageMin <= props.age && camps.ageMax >= props.age) || props.age == 0)
              && (camps.cost <= props.cost || props.cost == 100)
              ){
                const campLatLon = camps.geoJson.split(",");
              return (
                <Marker
                  
                  key = {camps.rangeKey}
                  promoteListing = {camps.promoted ? camps.promoted : false}
                  lat={parseFloat(campLatLon[0])}
                  lng={parseFloat(campLatLon[1])}
                  name={camps.locDescription}
                  location={camps.organization}
                  title={camps.title}
                  color={"#0583F2"}
                  
                />
              )
             
             }
            }
            )}    
            
          </GoogleMapReact>
        </div>
      );
    }

export default SimpleMap;