import { format, set } from 'date-fns'
import location from '../marker.svg';
import Moment from 'react-moment';
import moment from 'moment';
import circleImg from '../circle-check-solid-blue.svg';

function PromoCard(props) {
  let days = props.dayOfTheWeek.toString().split(" ").join("");
  
  days = days.split(",")
  days = days.sort();
  days = days.toString();
  let time = false;

  console.log(typeof days);

  let startDate = moment(props.startDate, 'YYY:MM:DD').format('MMM DD');
  let endDate = moment(props.endDate, 'YYY:MM:DD').format('MMM DD');

  if(days == "Fri,Mon,Sat,Sun,Thu,Tue,Wed"){
    days = "Mon - Sun";
  }else if(days == "Fri,Mon,Thu,Tue,Wed"){
    days = "Mon - Fri";
  }else if(days == "Fri,Mon,Sat,Thu,Tue,Wed"){
    days = "Mon - Sat";
  }else if(days == "Fri,Thu,Tue,Wed"){
    days = "Tue - Fri";
  }

  if(props.type == "Overnight"){
    time = true;
  }

  var now = new Date();
  now.setHours(0,0,0,0);
  let registration;
  if(props.registrationOpens){
    if (now < new Date(props.registrationOpens)) {
      registration = <p className="registration">Registration: <span className="blue">Starts on <Moment format="MM/DD/YYYY">{props.registrationOpens.replace(/-/g, '\/').replace(/T.+/, '')}</Moment> </span></p>;
    } else {
      registration =  <p className="registration">Registration: <span className="green">Open</span></p> ;
    }
}else{
  registration =  <></> ;
}

function distance(lat1,
  lat2, lon1, lon2)
{

// The math module contains a function
// named toRadians which converts from
// degrees to radians.
lon1 =  lon1 * Math.PI / 180;
lon2 = lon2 * Math.PI / 180;
lat1 = lat1 * Math.PI / 180;
lat2 = lat2 * Math.PI / 180;

// Haversine formula
let dlon = lon2 - lon1;
let dlat = lat2 - lat1;
let a = Math.pow(Math.sin(dlat / 2), 2)
+ Math.cos(lat1) * Math.cos(lat2)
* Math.pow(Math.sin(dlon / 2),2);

let c = 2 * Math.asin(Math.sqrt(a));

// Radius of earth in kilometers. Use 3956
// for miles
let r = 3956;

// calculate the result
let results = c * r;
return(Math.round( results * 100 + Number.EPSILON ) / 100);
}




  return (
    <div className="promoCard">
    <a id={props.campid} key={props.campid} href={props.campWebsite + "?utm_source=summercampscout&utm_medium=Referral&utm_campaign=promotedCampListing"} className="campcardPromo camp" target="_blank">
    <div className="proplogo onepromo"><img src={"https://static.summercampscout.com/media/camps/" + props.campImage } /></div>
    <div className="twopromo">
      <h2 className="blue cardTitle">{props.title} <span>| {props.organization}</span></h2>
      <h3 className="campcardLocation"><img className="cardlocation" src={location} />{props.locationDescription}</h3>
      {<p className="carddistance">{distance(props.lat, props.loclat, props.lon, props.loclon)} miles from {props.zip}</p>}
      {props.specialNeeds ? <p className="specialNeeds"><img className="cardlocation" src={circleImg} /> Special Needs Friendly</p> : <></>}
      <p className="camp-date"><span>{startDate != endDate ? startDate + " - " + endDate : startDate}</span></p>
      {time == true &&
       <p>{days}&nbsp;{props.type == "Day & Overnight" ? " (Day & Overnight)" : ''}{props.type == "Overnight" ? " (Overnight) " : ''}</p>
      }
      {time == false &&
        <p className="camp-date">{days},&nbsp; {moment(props.startDateTime, 'HH:mm').format('h:mm A')} - {moment(props.endDateTime, 'HH:mm').format('h:mm A')}</p>
      }
      {registration}
      
    </div>
      <div className="rightCard threepromo">
      <p className="camp-age">Ages: {props.minAge} - {props.maxAge}</p>
      <p className="campcost">${props.cost}</p>
      {/* <p className="weekCost">${Math.round(props.cost / 7)} per week</p> */}
      </div> 
      <div className="promodescription fourpromo" lg="12">
        <p className="promodescriptiontext">{props.campDescription}</p>
        <p className="promotedtext">Promoted</p>
      </div>
      </a>       
          </div>            
  );
}

export default PromoCard;
