
import { Outlet, Link, useNavigate, createSearchParams,useLocation,useParams } from "react-router-dom";

import React, { useState, useEffect, useRef, ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from '../logo.svg';
import Hamburger from 'hamburger-react'

import sliderIcon from '../slider-icon.png';

const query = `query {
  globalSettingsCollection(limit: 1){
      items{
        title
        notifications
        gtMid
        submitThankyou
        campListingPreviewCopy
        noCampsFound
        menuCollection{
          items{
                title
                slug
        		}
        
        }
        }
      }
    }` 
  

function extractFromAdress(components, type){
    for (var i=0; i<components.length; i++)
        for (var j=0; j<components[i].types.length; j++)
            if (components[i].types[j]==type) return components[i].short_name;
    return "";
}

export default function PageHeader(props){
  

    let [zipcodeSearch, setZipcodeSearch] = useState("");
    let [citySearch, setCitySearch] = useState("");
    
    let [stateSearch, setStateSearch] = useState("");
    //let [zip, setZip] = useState(null);
    let [startDate, setStartDate] = useState(null);
    let [endDate, setEndDate] = useState(null);
    let [distance, setDistance] = useState(null);
    let [cost, setCost] = useState(null);
    
    
    let [notification, setNotification] = useState('');
    let [filter, setFilter] = useState(false);
    const zipInput = useRef(null);
    const searchInput = useRef(null);
    const zipInputMobile = useRef(null);
    // const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [globalData, setGlobalData] = useState(null);  


    useEffect(() => {
      setOpen(false);
      document.body.style.overflow = 'auto';
    }, []);

    
      // function setFilterSettings(filterSetting){
      //   props.setFilter(filterSetting)
      //   props.setFilterDescription(true)
      // }
      function setMenu(){
        setOpen(!isOpen);
        if (!isOpen) {
          document.body.style.overflow = 'hidden';
        }else{
          document.body.style.overflow = 'auto';
        }
      }

      function Alert(props) {
        return (
          
            <div className="notification container-full">
              <div className="row">
                <div className="col-md-12">
                    <p>{props.alert}</p>
                </div>
              </div>
            </div>
          
        )
      }

      useEffect(() => {
        window
          .fetch(`https://graphql.contentful.com/content/v1/spaces/y1wwfz3pz3en/?access_token=${process.env.REACT_APP_CONTENTFUL_KEY}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // Authenticate the request
              Authorization: "Bearer [YOUR_ACCESS_TOKEN]",
            },
            // send the GraphQL query
            body: JSON.stringify({ query }),
          })
          .then((response) => response.json())
          .then(({ data, errors }) => {
            if (errors) {
              console.error(errors);
            }
      
            // rerender the entire component with new data
            console.log(data.globalSettingsCollection.items[0]);
            setGlobalData(data.globalSettingsCollection.items[0]);
            // setAlert(data.globalSettingsCollection.items[0].notifications)
            
          });
      }, []);

      return (
        
        <div className="topSection">
        {!globalData ? <Alert alert={props.alert} />  : 
            
        <header className="page-header">
          <div className="header pageHeader">
              <div className="logo">
              <Link to="/" >
                <img src={logo} alt="Logo" />
              </Link>
              </div>
            
              <div className="subbutton subbuttonDesktop">
            
              <div className={"mobileMenu mobileMenuShow"}>
             
                
                <div className="searchbox navBox">
                <div className="navWrapper">
                  {globalData.menuCollection.items.map((menu) => {
                    return (
                      <a className="navItem" key={menu.slug} href={"/" + menu.slug}>{menu.title}</a>
                    )
                })
              }
                </div>
                </div>
              
              <div className="buttonsection">
              <Link to="https://provider.summercampscout.com/" className="cta-submit">
                Submit Camps
              </Link> 
              <div className="btndescription">
              <p>It's Free!</p>
              </div>
              
              </div>
            </div>
              </div>
            
            <div className="subbutton subButtonMobile">
            {isOpen &&
              <div className={"mobileMenu mobileMenuShow"}>
             
                
                <div className="searchbox navBox">
                <div className="navWrapper navWrapperOpen">
                  {globalData.menuCollection.items.map((menu) => {
                    return (
                      <a className="navItem" key={menu.slug} href={"/" + menu.slug}>{menu.title}</a>
                    )
                })
              }
                </div>
                </div>
              
              <div className="buttonsection">
              <Link to="https://provider.summercampscout.com/" className="cta-submit">
                Submit Camps
              </Link> 
              <div className="btndescription">
              <p>It's Free!</p>
              </div>
              
              </div>
            
              </div>
            }  
            
              <div className="menuBtn">
             <Hamburger toggled={isOpen} toggle={setMenu} /> 
             </div>
            </div>
             
          </div>
         
        </header>  
            }
        </div>
       
        
        

);

}

